import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'
import { BaseService } from './BaseService'
import { IApiCallerConfig } from 'common/helpers/api-caller.helper'

export class EmployeesService extends BaseService {
  endpoint = 'automation/employee/officer'
  managerUsersEndpoint = 'automation/employee/manager/users'

  constructor() {
    super()
  }

  async getList(filters?: Lib.T.Employees.GetListFilters): Promise<Lib.T.Employees.GetListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.employees) {
        return { success: false, total: 0 }
      }
      return { success: true, employees: data.employees, total: data.total }
    
  }

  async getEmployee(filters?: Lib.T.Employees.GetEmployeeDataFilters, id?: number): Promise<Lib.T.Employees.GetEmployeeResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat(id ? `/${id}` : '')
        .concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data) {
        return { success: false }
      }
      return { success: true, employee: data }
    
  }

  async getManagerUsersList(): Promise<Lib.T.Employees.GetEmployeeResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.managerUsersEndpoint),

      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data) {
        return { success: false }
      }
      return { success: true, users: data }
    
  }

  async editEmployee(args: Lib.T.Employees.EditEmployeeArgs, id?: number): Promise<Lib.T.Employees.CreateUserResult> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id ? `/${id}` : ''),
      headers: { authorization: this.accessToken },
      data: args,
    }

    const response = this.callRefresh(config)
      const { status } = await response
      if (id) {
        if (status !== 200) {
          return { success: false }
        }
      } else {
        if (status !== 201) {
          return { success: false }
        }
      }

      return {
        success: true,
      }
    
  }

  async createEmployee(args: Lib.T.Employees.CreateEmployeeArgs): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 201
    
  }
}
