import * as types from 'common/constants/action-types'
import { ReducerType } from 'common/typing/reducer-payload.type'
import { SavedUser } from 'common/typing/saved-user.interface'
import moment from 'moment'

export interface initialStateType {
  user: SavedUser
  sidebar: boolean
  fsLoader: boolean
  newCreatedPiece: {}
  sidebarDisabled: boolean
  unReadNotifications: number
  unApprovedProblems: number
  socket: any
  showToast: boolean
  unSeenMessages: number
  date: string
}

const initialState: initialStateType = {
  user: { accessToken: '', refreshToken: '', id: 0, group: { id: 0, name: 'Yönetim' }, username: '', phone: '', time: '',
    avatar: {
      image: '',
      imageLink: '',
      imageName: '',
      imageThumbnail: '',
    },
    permissions: {
      primaryRole: {
        department: '',
        role: '',
        permissions: 'admin-access',
      },
      secondaryRoles: [
        {
          department: '',
          role: '',
          permissions: '*-secondary',
        }
      ],
      },
    },
  sidebar: false,
  fsLoader: false,
  newCreatedPiece: {},
  sidebarDisabled: false,
  unReadNotifications: 0,
  unApprovedProblems: 0,
  unSeenMessages: 0,
  socket: undefined,
  showToast: false,
  date: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
}

export const globals = (state: initialStateType = initialState, { type, payload }: ReducerType): initialStateType => {
  switch (type) {
    case types.SAVE_USER:
      return {
        ...state,
        user: payload,
      }

    case types.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebar: payload,
      }

    case types.FULL_SCREEN_LOADER:
      return {
        ...state,
        fsLoader: payload,
      }
    case types.SET_NEW_CREATED_PIECE:
      return {
        ...state,
        newCreatedPiece: payload,
      }

    case types.TOGGLE_DISABLE_SIDEBAR:
      return {
        ...state,
        sidebarDisabled: payload,
      }

    case types.GET_UNREAD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        unReadNotifications: payload,
      }

    case types.GET_UNREAD_NOTIFICATION_FAIL:
      return {
        ...state,
        unReadNotifications: 0,
      }

    case types.UPDATE_UNAPPROVED_PROBLEMS:
      return {
        ...state,
        unApprovedProblems: payload,
      }

    case types.GET_UNSEEN_MESSAGES_SUCCESS:
      return {
        ...state,
        unSeenMessages: payload,
      }

    case types.GET_UNSEEN_MESSAGES_FAIL:
      return {
        ...state,
        unSeenMessages: 0,
      }

    case types.SET_SOCKET:
      return {
        ...state,
        socket: payload,
      }

    case types.HANDLE_TOGGLE_TOAST:
      return {
        ...state,
        showToast: !state.showToast,
      }

    case types.SET_DATE:
      return {
        ...state,
        date: payload,
      }

    default:
      return state
  }
}
