import { BaseService } from './BaseService';
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'
import _ from 'lodash'
import swal from 'sweetalert'
import * as Lib from './lib'
import { ErrorType } from './lib/types/errorType'
import {
  Order,
  OrderInGetCard,
  sendOrderToProdcutionType,
  SingleOrder,
} from './lib/types/orders'
import { Piece } from './lib/types/pieces'

export class OrdersService extends BaseService{
  endpoint = 'orders/'
  deleteCustomerEndpoint = 'customer-orders/'

  constructor() {
    super();
  }

  async addToCart(data: Lib.T.Order.AddToCartArgs): Promise<{ success: boolean; error: ErrorType | null }> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      customConfigs: { hidePopup: true },
      data,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 201) {
        return { success: false, error: await response }
      }

      return { success: true, error: null }
    
  }

  async editAddToCart(data: Lib.T.Order.AddToCartArgs, orderId: number): Promise<{ success: boolean; error: ErrorType | null }> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`${orderId}/monacoProduct`),
      headers: { authorization: this.accessToken },
      customConfigs: { hidePopup: true },
      data,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 201) {
        return { success: false, error: await response }
      }

      return { success: true, error: null }
    
  }

  async calculateWeight(args: Lib.T.Order.CalculateWeightArgsType): Promise<{ success: boolean; weight: number }> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) + 'calculateWeight',
      data: args,
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 201) {
        return { success: false, weight: 0 }
      }
      return { success: true, weight: data.weight }
    
  }

  async findOne(id: number, args?: { productCategory?: number; productSubCategory?: number }): Promise<Lib.T.Order.FindOneResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + id).concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200 && data.order) {
        return {
          success: true,
          order: data.order,
        }
      }

      return { success: false, status  }
    
  }

  async findOnePDF(id: number): Promise<Lib.T.Order.FindOneResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + id).concat('?pdf=true'),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200 && data.order) {
        return {
          success: true,
          order: data.order,
        }
      }

      return { success: false, status  }
    
  }

  async originalOrderPDF(id: number): Promise<Lib.T.Order.FindOneOriginalResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat('customer-orders/original-order/' + id),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200 && data) {
        return {
          success: true,
          order: data,
        }
      }

      return { success: false, status  }
    
  }

  async getCart(args?: { productCategory?: number; productSubCategory?: number }): Promise<Lib.T.Order.GetCartResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('getCard')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200 && !_.isEmpty(data.order)) {
        return { success: true, order: data.order ? data.order : undefined }
      } else {
        return { success: false }
      }
    
  }

  async getCustomerImages(id: number): Promise<Lib.T.Order.GetCustomerImagesResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat(id.toString()),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return { success: true, images: data?.order?.customerImages }
      } else {
        return { success: false }
      }
    
  }

  async productProblems(args?: {
    limit: number
    page: number
    sort?: string
    keyword?: string
  }): Promise<Lib.T.Order.ProductProblemsResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('productProblems')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
      customConfigs: {hidePopup: true}
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return { success: true, problems: data.problems, total: data.total }
      } else {
        return { success: false, total: 0 }
      }
    
  }

  async historyProductProblems(args?: {
    limit: number
    page: number
    sort?: string
    keyword?: string
  }): Promise<Lib.T.Order.HistoryProductProblemsResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('productProblemsHistory')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return { success: true, problems: data.problems, total: data.total }
      } else {
        return { success: false, total: 0 }
      }
    
  }

  async deleteProductProblem(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`productProblemsHistory/${id}`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
    
  }

  async copyOrder(orderId: number): Promise<Lib.T.Order.copyOrderResponse> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(orderId.toString()).concat('/copy'),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status === 201) {
        return { success: true }
      } else {
        return { success: false }
      }
    
  }

  async edit(orderData: Lib.T.Order.EditOrCreateOrderType, id: number): Promise<OrderInGetCard | null> {
    if (orderData) {
      const config: IApiCallerConfig = {
        method: 'PATCH',
        url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id.toString()),
        headers: { authorization: this.accessToken },
        data: orderData,
      }
      const response = this.callRefresh(config)
        const { status, data } = await response

        if (status) {
          return data.order
        } else {
          return null
        }
    } else {
      return null
    }
  }

  async editPieceRoleDescription(
    data: Lib.T.Order.EditPieceRoleDescriptionType,
    id: number,
  ): Promise<{ success: boolean; pieces: Piece[] | null }> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id.toString()).concat('/piece'),
      headers: { authorization: this.accessToken },
      data,
    }
    const response = this.callRefresh(config)
      const { status, data: responseData } = await response

      if (status !== 200) {
        return { success: false, pieces: null }
      }

      return { success: true, pieces: responseData.pieces }
    
  }

  async approveProductProblem(reason: string, id: number, changeWeights: boolean | undefined): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('productProblems/').concat(id.toString()).concat('/approve'),
      headers: { authorization: this.accessToken },
      data: { reason, changeWeights },
    }
    const response = this.callRefresh(config)
      const { status } = await response

      if (status !== 201) {
        return false
      }
      return true
    
  }

  async readAll(args?: Lib.T.Order.ReadAllArgs): Promise<Lib.T.Order.ReadAllResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false }
      }

      return { success: true, orders: data.orders, total: data.total, totalWeight: data.totalWeight }
    
  }

  async readAllTwo(args?: Lib.T.Order.ReadAllArgs): Promise<Lib.T.Order.ReadAllResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat('order-tracking')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        swal({
          text: 'Siparişler listesine ulaşılamadı. Lütfen tekrar deneyin.',
          icon: 'error',
          dangerMode: true,
        })
        return { success: false }
      }

      return {
        success: true,
        orders: data.orders,
        total: data.total,
      }
    
  }

  async readAllWeight(args?: Lib.T.Order.ReadAllArgs): Promise<Lib.T.Order.ReadAllResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat('order-tracking/calculate-weights')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false }
      }

      return {
        success: true,
        castingTotalWeight: data.castingTotalWeight,
        orderPendingWeight: data.orderPendingWeight,
        orderApprovedWeight: data.orderApprovedWeight,
        orderTotalWeight: data.orderTotalWeight,
        polish1Weight: data.polish1Weight,
        polish2Weight: data.polish2Weight,
        storeWeight: data.storeWeight,
        tezgahWeight: data.tezgahWeight,
        totalWeight: data.totalWeight,
        customerWeight: data.customerWeight,
      }
    
  }

  async getLogs(args?: Lib.T.Order.getLogsArgs): Promise<Lib.T.Order.GetLogsResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('logs')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false }
      }

      return { success: true, orderLogs: data.orderLogs, total: data.total }
    
  }

  async getSingleLogs(id: string): Promise<Lib.T.Order.GetSingleLogResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('logs')
        .concat('/' + id),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false }
      }

      return { success: true, orderLog: data.orderLog }
    
  }

  async getDeletedPieces(productId: number, orderId: string): Promise<{ success: boolean; hasNewPieces?: boolean }> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat(orderId)
        .concat(`/sozerProduct?productId=${productId.toString()}`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false }
      }

      return { success: true, hasNewPieces: data.hasNewPieces }
    
  }

  async addDeletedPieces(productId: number, orderId: string): Promise<Lib.T.Order.AddDeletedPiecesResponse> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(orderId).concat('/sozerProduct'),
      headers: { authorization: this.accessToken },
      data: { productId },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 201) {
        return { success: false }
      }

      return { success: true, pieces: data.pieces }
    
  }

  async create(args: Lib.T.Order.CreateOrderArgs): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('submitNew'),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 201
    
  }

  async newCreate(args: Lib.T.Order.CreateNewOrderArgs): Promise<{ success: boolean; order: Order | null }> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('submitNew'),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status,data } = await response
      if (status === 201) {
        return { success: true, order: data.order }
      } else {
        return { success: false, order: null }
      }
    
  }

  async editProduct(
    args: Lib.T.Order.EditArgs,
    orderId: number,
    filter?: { productCategory: number; productSubCategory: number },
  ): Promise<{ success: boolean; order: SingleOrder | null }> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat(orderId.toString())
        .concat(filter ? DOM.objectToSearch(filter) : ''),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status,data } = await response
      if (status === 200) {
        return { success: true, order: data.order }
      }
      return { success: false, order: null }
    
  }

  async correctWeight(args: Lib.T.Order.CorrectWeightArgs, orderId: number): Promise<{ success: boolean; product: any | null }> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(orderId.toString()).concat('/correctWeight'),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status,data } = await response
      if (status === 200) {
        return { success: true, product: data.order }
      }
      return { success: false, product: null }
    
  }

  async weightReport(args: Lib.T.Order.WeightReportArgs): Promise<{ success: boolean; report: any | null }> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('weightReport')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status,data } = await response
      if (status !== 200) {
        return { success: false, report: null }
      }
      return { success: true, report: data.report }
    
  }

  async reduceCount(args: Lib.T.Order.reduceCountArgs, orderId: number): Promise<{ success: boolean }> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(orderId.toString()).concat('/reduceCount'),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status === 200) {
        return { success: true }
      } else {
        return { success: false }
      }
    
  }

  async returnToCard(orderId: number): Promise<{ success: boolean }> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(orderId.toString()).concat('/returnToCard'),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status === 200) {
        return { success: true }
      } else {
        return { success: false }
      }
    
  }

  async acceptPrice(orderId: number): Promise<{ success: boolean }> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.deleteCustomerEndpoint).concat(orderId.toString()).concat('/toggleShowPrice'),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status === 200) {
        return { success: true }
      } else {
        return { success: false }
      }
    
  }

  async sendToStore(args: { deliveryDate: string }, orderId: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) + orderId + '/sendToStore',
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return false
      }

      return true
    
  }

  async delete(args: Lib.T.Order.DeleteArgs, orderId: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) + orderId,
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
    
  }
  async deleteOrder(
    args: { wholeOrder?: boolean; deleteReason?: string; productIds?: number[]; pieceIds?: number[] },
    orderId: number,
  ): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) + orderId + '/delete',
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
    
  }

  async deleteCustomerImage(args: Lib.T.Order.DeleteCustomerImage, orderId: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.deleteCustomerEndpoint) + orderId,
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
    
  }

  async changeState(args: Lib.T.Order.ChangeStateArgs, productId: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) + productId + '/changeState',
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
    
  }

  async changeHighLight(args: any, orderId: number, query?: any): Promise<{ result: boolean; order?: SingleOrder | null }> {
    const config: IApiCallerConfig = {
        method: 'PATCH',
        url:
          process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) +
          orderId +
          '/changeHighlight'.concat(args ? DOM.objectToSearch(query) : ''),
        headers: { authorization: this.accessToken },
        data: args,
      }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { result: false, order: null }
      }
      return { result: status === 200, order: data.order }
    
  }

  async myOrders(args?: Lib.T.Order.ReadAllArgs): Promise<Lib.T.Order.ReadMyOrderResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('myOrders')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false }
      }

      return { success: true, orders: data.orders, total: data.total }
    
  }

  async getStones(orderId: number): Promise<Lib.T.Order.ReadMyOrderStones> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(orderId.toString()).concat('/stones'),

      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false }
      }

      return { success: true, stones: data.stones, total: data.total }
    
  }
  async orderTracking(args?: Lib.T.Order.ReadAllArgs, subUrl?: string): Promise<Lib.T.Order.orderTrackingResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat(subUrl ? subUrl : '')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false }
      }

      return { success: true, orders: data.orders, total: data.total, totalWeight: data.totalWeight }
    
  }

  async orderTrackingRefactored(args?: Lib.T.Order.ReadAllArgs, subUrl?: string): Promise<Lib.T.Order.orderTrackingResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(subUrl ? subUrl : '')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false }
      }

      return { success: true, orders: data.orders, total: data.total, totalWeight: data.totalWeight }
    
  }

  async orderProfit(orderId: number): Promise<Lib.T.Order.OrderProfitResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) + orderId + '/profits',

      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false }
      }
      return { success: true, order: data.order, total: data.total }
    
  }

  async sendOrderToProduction(args: { deliveryDate: string }, orderId: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) + orderId + '/sendToProduction',
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status === 406) {
        return false
      }
      return status === 200;
    
  }

  async editSendOrderToProduction(args: sendOrderToProdcutionType, orderId: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) + 'updateDeliveryDate/' + orderId,
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return false
      }

      return true
    
  }
  async sendToApproved(args: sendOrderToProdcutionType, orderId: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) + orderId + '/approve',
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return false
      }

      return true
    
  }

  async sendNotification(orderId: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) + orderId + '/sendNotification',
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 201) {
        return false
      }

      return true
    
  }

  async rejectOrder(orderId: number, rejectReason?: string): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) + orderId,
      headers: { authorization: this.accessToken },
      data: { rejectReason },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return false
      }

      return true
    
  }

  async sendToProduction(orderId: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) + orderId + '/returnToProduction',
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return false
      }

      return true
    
  }

  async fetchMonacoEdit(orderId: number, productId: number): Promise<{ success: boolean; data: Lib.T.Order.MonacoEditData[] | null }> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint) + orderId + `/monacoProduct?productId=${productId}`,
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false, data: null }
      }

      return { success: true, data: data.pieces }
    
  }
  async submitNew(data: Lib.T.Order.SubmitNewArgs): Promise<{ success: boolean; order: Order | null }> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('submitNew'),
      headers: { authorization: this.accessToken },
      data,
    }
    const response = this.callRefresh(config)
      const { status, data: result } = await response

      if (status !== 200) {
        return { success: false, order: null }
      }

      return { success: true, order: result.order }
    
  }

  static dontAccept() {
    throw 'not-acceptable'
  }
}
