import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'
import { BaseService } from './BaseService'
import { IApiCallerConfig } from 'common/helpers/api-caller.helper'

export class AutomationNeighbourhoodService extends BaseService {
  endpoint = 'automation/neighbourhood'
  permissionEndpoint = 'automation/permission/list'

  constructor() {
    super()
  }

  async getList(filters?: Lib.T.AutomationServiceIds.GetListFilters): Promise<Lib.T.AutomationNeighbourhood.GetListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return { success: true, neighbourhoods: data.neighbourhoods, total: data.total }
      }
      return { success: false, total: 0, neighbourhoods: [] }
  }

  async getPermissionList(): Promise<Lib.T.AutomationNeighbourhood.GetPermissionListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.permissionEndpoint).concat('?getGrouped=true'),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return { success: true, permissions: data.permissions, total: data.total }
      }
      return { success: false, total: 0, permissions: [] }
  }

  async create(args: Lib.T.AutomationNeighbourhood.CreateArgs): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    }

    const response = this.callRefresh(config)
      const { status } = await response
      return status === 201
  }

  async edit(args: Lib.T.AutomationNeighbourhood.EditArgs, id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    }

    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
  }
}
