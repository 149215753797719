import { BaseService } from './BaseService';
import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class AutomationDrinks extends BaseService {
  endpoint = 'automation/restaurant/drink/manage'

  constructor() {
    super();
  }

  async getDrinks(filters?: Lib.T.Drinks.GetListFilters): Promise<Lib.T.Drinks.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.drinkOrders) {
        return { success: false }
      }
      return { success: true, drinkOrders: data.drinkOrders, total: data.total }
  }
}
