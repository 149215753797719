import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class OvertimeService extends BaseService{
  endpoint = 'automation/overtime/officer'

  constructor() {
    super();
  }

  async getList(filters?: Lib.T.Overtime.GetListFilters): Promise<Lib.T.Overtime.GetListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response

      if (status !== 200 || !data.overtimes) {
        return { success: false, total: 0 }
      }
      return { success: true, overtimes: data.overtimes, total: data.total }
    
  }

  async AddEmployeeToOvertime(args: Lib.T.Overtime.AddEmployeeArgs): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat('automation/overtime/officer'),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 201
    
  }

  async ChangeNeedService(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(`automation/overtime/officer/${id}/toggle-need-service`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 201
    
  }

  async deleteOvertime(ids: number[]): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: {ids: ids}
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
    
  }
}
