import * as Lib from './lib'
import { BaseService } from './BaseService'
import { IApiCallerConfig } from 'common/helpers/api-caller.helper'

export class AreaService extends BaseService{
  endpoint = 'area'
  constructor() {
    super();
  }

  async createArea(args: string): Promise<Lib.T.Options.CreateOrEditReturnType> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: { code: args },
    }
    const response = this.callRefresh(config)

    const { status } = await response
    if (status !== 201) {
      return { success: false }
    }

    return {
      success: true,
    }
  }

  async edit(id: number, args: string): Promise<Lib.T.Options.CreateOrEditReturnTypeArea> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat('area/').concat(id.toString()),
      headers: { authorization: this.accessToken },
      data: { code: args },
    }
    const response = this.callRefresh(config)

    const { status } = await response
    if (status !== 200) {
      return { success: false }
    }

    return { success: true }
  }

  async readArea(): Promise<Lib.T.Options.ReadReturnTypeArea> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)

    const { status, data } = await response
    if (status !== 200) {
      return { success: false, total: 0 }
    }

    const areaData: Lib.T.Options.ReadDataTypeArea = data

    return {
      success: true,
      data: areaData,
      total: areaData.total,
    }
  }

  async readSingleArea(id: number): Promise<Lib.T.Options.ReadReturnTypeArea> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat('area/')!.concat(id.toString()),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)

    const { status, data } = await response
    if (status !== 200) {
      return { success: false, total: 0 }
    }

    const areaData: Lib.T.Options.ReadDataTypeArea = data

    return {
      success: true,
      data: areaData,
    }
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`area/${id}`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
    const { status, data } = await response
    return !(status !== 200 || data !== '');
  }
}
