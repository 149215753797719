import { BaseService } from './BaseService'
import * as Lib from './lib'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class FoodMenuService extends BaseService {
  endpoint = 'automation/restaurant/food-menu/manager'

  constructor() {
    super();
  }

  async getList(filters?: Lib.T.FoodMenu.GetListFilters): Promise<Lib.T.FoodMenu.GetListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.foodMenus) {
        return { success: false, total: 0 }
      }
      return { success: true, foodMenus: data.foodMenus, total: data.total }
    
  }

  async getFoodMenu(id?: number): Promise<Lib.T.FoodMenu.GetFoodMenuResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat(id ? `/${id}` : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data) {
        return { success: false }
      }
      return { success: true, foodMenu: data }
    
  }

  async editFoodMenu(args: Lib.T.FoodMenu.EditFoodMenuArgs, id?: number): Promise<Lib.T.FoodMenu.EditResult> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id ? `/${id}` : ''),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (id) {
        if (status !== 200) {
          return { success: false }
        }
      } else {
        if (status !== 201) {
          return { success: false }
        }
      }

      return {
        success: true,
      }
    
  }

  async createFoodMenu(args: Lib.T.FoodMenu.CreateFoodMenuArgs): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 201
    
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200;
    
  }
}
