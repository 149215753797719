import * as Lib from './lib'
import { BaseService } from './BaseService';
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class NotificationsService extends BaseService{
  endpoint = 'messages'

  constructor() {
    super();
  }

  async getNotifications(args?: Lib.T.Notifications.GetListArgs): Promise<Lib.T.Notifications.Notifications> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(
        args
          ? DOM.objectToSearch({
              ...args,
            })
          : '',
      ),
      headers: { authorization: this.accessToken },
      customConfigs: {hidePopup: true}
    }
    const response = this.callRefresh(config)
      const { status, data } = await response

      if (status !== 200) {
        return { success: false, total: 0, totalUnread: 0 }
      }
      return { success: true, total: data.total, totalUnread: data.totalUnread, data: data.messages }
    
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/${id}`),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return false
      }
      return true
    
  }
  async makeRead(id: number): Promise<Lib.T.Notifications.Notifications> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/${id}`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false, total: 0, totalUnread: 0 }
      }
      return { success: true, total: data.total, totalUnread: data.totalUnread, data: data.messages }
    
  }
}
