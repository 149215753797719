import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import swal from 'sweetalert'
import * as Lib from './lib'
import { bucketName } from './lib/types/medias'

export class MediaService extends BaseService{
  constructor() {
    super();
  }

  async upload(file: File, bucketName: bucketName, endPoint?: string): Promise<Lib.T.Medias.UploadReturnType> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('bucketName', bucketName)

    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(endPoint ? endPoint : 'minio/upload'),
      data: formData,
      headers: { authorization: this.accessToken },
      customConfigs: { hidePopup: true },
    }

    const response = this.callRefresh(config)

    const { status, data } = await response
    if (status !== 201 || !data) {
      return { thumbnail: '', name: '', imageLink: '', success: false }
    }

    const responseData: Lib.T.Medias.UploadResponse = data
    const { media } = responseData
    if (!media || !media.thumbnail || !media.file_name || !media.link) {
      return { thumbnail: '', name: '', imageLink: '', success: false }
    }

    return {
      thumbnail: media.thumbnail,
      success: true,
      name: media.file_name,
      imageLink: media.link,
    }
  }

  async download(imageName: string): Promise<{ success: boolean; data: any }> {

    const config: IApiCallerConfig = {
        headers: { authorization: this.accessToken },
        method: 'GET',
        url: imageName,
        responseType: 'blob',
        customConfigs: { hidePopup: true },
      }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 201){
        return { success: false, data: null }
      }
      return { success: true, data: data }
  }
}
