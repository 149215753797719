import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import {
  OvertimeService,
  AutomationServiceIdsService,
  AutomationDepartmentService, EmployeesService,
} from 'common/services'
import { useEffect, useState } from 'react'
import * as Lib from '.'
import { imageAddresser } from 'common/helpers/image.helper'
import { Moment } from 'moment'
import moment from 'moment'
import swal from 'sweetalert'

export const useOvertime = () => {
  const { setLoader } = useLoader()

  const [showDepartmentModal, setShowDepartmentModal] = useState(false)
  const [showServiceModal, setShowServiceModal] = useState(false)
  const [fetchAgain, setFetchAgain] = useState(false)
  const [query, setQuery] = useState({ name: '', departmentId: undefined, serviceId: undefined, date: moment().format('YYYY-MM-DD') })
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const tableColumns = ['', 'Ad', 'Servis', 'Departman', 'Tarih', '']
  const tableSizes = ['3%', 'unset', 'unset', 'unset', 'unset', 'unset']

  const [departmentTableData, setDepartmentTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const departmentTableColumns = ['Departman', 'Kişi']
  const departmentTableSizes = ['unset', 'unset']

  const [serviceTableData, setServiceTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const serviceTableColumns = ['Servis', 'Kişi']
  const serviceTableSizes = ['unset', 'unset']

  const [serviceIdSelected, setServiceIdSelected] = useState<number | undefined>()
  const [serviceIds, setServiceIds] = useState([{ id: 0, title: '' }])
  const [departmentIdSelected, setDepartmentIdSelected] = useState<number | undefined>()
  const [departmentIds, setDepartmentIds] = useState([{ id: 0, title: '' }])
  const [totalData, setTotalData] = useState<number | undefined>()
  const [paginate, setPaginate] = useState({ limit: 30, page: 1 })
  const [date, setDate] = useState<string | undefined | Moment>('')
  const [showAddNewModal, setShowAddNewModal] = useState(false)
  const [employeeData, setEmployeeData] = useState<
    | {
    name: string
    isParent: string
    isSelected: boolean
    id: number
    departmentId?: number | undefined
    disabled: boolean
  }[]
    | []
    >([])
  const [searchResult, setSearchResult] = useState<
    | {
    name: string
    isParent: string
    isSelected: boolean
    id: number
    departmentId?: number | undefined
    disabled: boolean
  }[]
    | []
    >([])
  const [isSearch, setIsSearch] = useState(false)
  const [searchValue, setSearchValue] = useState({name: '', departmentId : undefined})
  const [overtimes, setOvertimes]:any = useState([])
  const [allSelected, setAllSelected] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [overtimeId, setOvertimeId] = useState(0)

  const tableIdIndex = 7
  const departmentTableIdIndex = 3
  const serviceTableIdIndex = 3

  const handleModal = () => {
    setDepartmentIdSelected(undefined)
    setServiceIdSelected(undefined)
  }

  const handleCancelDepartmentModal = () => {
    setShowDepartmentModal(prev => !prev)
  }

  const handleCancelServiceModal = () => {
    setShowServiceModal(prev => !prev)
  }

  const addItemAtFirstIndex = (array: Lib.T.Item[], newItem: Lib.T.Item): Lib.T.Item[] => {
    return [newItem, ...array]
  }

  const handleNeedService = async (id: number) => {
    const service = new OvertimeService()
    setLoader(true)
    const status = await service.ChangeNeedService(id)
    if (status) {
      await getOvertime()
      setLoader(false)
    }
    setLoader(false)
  }

  const getOvertime = async () => {
    const service = new OvertimeService()
    setLoader(true)
    const { success, overtimes, total } = await service.getList({
      limit: paginate.limit,
      responseType: 'date',
      name: query.name,
      departmentId: query.departmentId,
      serviceId: query.serviceId,
      date: query.date
    })
    overtimes && setOvertimes(overtimes)
    setLoader(false)
    if (success && overtimes) {
      setTotalData(parseInt(total! + ''))
      setTableData(prevData => [
        ...overtimes.map(overtime => {
          return [
            <Lib.C.TableImage src={overtime.employee.avatar ? imageAddresser(overtime.employee.avatar.imageThumbnail, true) : '/images/png/avatar.png'} />,
            <div>{`${overtime?.employee?.firstName} ${overtime?.employee?.lastName}`}</div>,
            <div>{overtime?.employee?.service?.name ?? '-'}</div>,
            <div>{overtime?.employee?.user?.userToRoles[0].department.name ?? '-'}</div>,
            <div>{moment(overtime?.date).format('DD/MM/YYYY')}</div>,
            <Lib.S.tableButtons>
              <Lib.C.TableButton
                icon="minibus"
                color={`${overtime.needService && overtime.employee?.service ? 'var(--accent)' : '#ccc'}`}
                size={25}
                callback={() => handleNeedService(overtime.id)}
              />
              <Lib.C.TableButton
                icon="trash_bin"
                callback={() => {
                  setOvertimeId(overtime.id)
                  setShowDeleteModal(true)
                }}
              />
            </Lib.S.tableButtons>,
          ]
        }),
      ])
    }
  }

  const getDepartmentsPopup = async () => {
    const service = new OvertimeService()
    setLoader(true)
    const { success, overtimes } = await service.getList({
      responseType: 'department',
      date: query.date
    })
    setLoader(false)
    if (success && overtimes) {
      setDepartmentTableData(prevData => [
        ...overtimes.map(overtime => {
          return [
            <div>{overtime?.department_name}</div>,
            <div>{overtime?.overtimes ?? '-'}</div>,
          ]
        }),
      ])
    }
  }

  const getServicePopup = async () => {
    const service = new OvertimeService()
    setLoader(true)
    const { success, overtimes } = await service.getList({
      responseType: 'service',
      date: query.date
    })
    setLoader(false)
    if (success && overtimes) {
      setServiceTableData(prevData => [
        ...overtimes.map(overtime => {
          return [
            <div>{overtime?.service_name}</div>,
            <div>{overtime?.overtimes ?? '-'}</div>,
          ]
        }),
      ])
    }
  }

  const handleDepartmentsPopup = async () => {
    await getDepartmentsPopup()
    setShowDepartmentModal(true)
  }

  const handleServicePopup = async () => {
    await getServicePopup()
    setShowServiceModal(true)
  }

  const getServiceIds = async () => {
    const service = new AutomationServiceIdsService()
    setLoader(true)
    const { success, services } = await service.getList()
    setLoader(false)
    if (success && services) {
      const newItem: Lib.T.Item = { id: 0, title: '' }
      const serviceIdItems = services.map(item => ({
        id: item?.id,
        title: item?.name,
      }))
      setServiceIds(addItemAtFirstIndex(serviceIdItems, newItem))
    }
  }

  const getDepartments = async () => {
    const service = new AutomationDepartmentService()
    setLoader(true)
    const { data, success } = await service.getList()
    setLoader(false)
    if (success && data) {
      setDepartmentIds(() => {
        return data.map(item => ({
          id: item?.id,
          title: item?.name,
        }))
      })
    }
  }

  const getEmployees = async () => {
    const service = new EmployeesService();
    const { success, employees } = await service.getList({
      limit: 99999,
      showAll: true,
    });
    if (success && employees) {
      setEmployeeData(
        employees.map(employee => {
          const isInOvertime = overtimes.some((overtime:any) => overtime.employee.id === employee.id);

          return {
            name: employee?.firstName + ' ' + employee?.lastName,
            id: employee.id,
            isParent: '',
            departmentId: employee?.user?.userToRoles[0]?.department?.id ?? undefined,
            isSelected: isInOvertime,
            disabled: isInOvertime,
          };
        })
      );

      setSearchResult(
        employees.map(employee => {
          const isInOvertime = overtimes.some((overtime:any) => overtime.employee.id === employee.id);

          return {
            name: employee?.firstName + ' ' + employee?.lastName,
            id: employee.id,
            isParent: '',
            departmentId: employee?.user?.userToRoles[0]?.department?.id ?? undefined,
            isSelected: isInOvertime,
            disabled: isInOvertime,
          };
        })
      );
    }
  };

  const handleSearch = (value:any) => {
    setIsSearch(true)
    const searchItems = employeeData.filter((item) => item?.name.toLocaleLowerCase().includes(value.currentTarget.value.toLocaleLowerCase()))
    setSearchResult(searchItems)
  }

  const handleSearchDepartment = (value:any) => {
    setIsSearch(true)
    const searchItems = employeeData.filter((item) => item?.departmentId === value)
    setSearchResult(searchItems)
  }

  const handleAddEmployee = async () => {
    const service = new OvertimeService()
    let employeeIds: number[] = []
    employeeData?.forEach(employee => {
        if (employee.isSelected && !employee.disabled) {
          employeeIds.push(employee.id)
        }
    })

    setLoader(true)
    const success = await service.AddEmployeeToOvertime({
      employeeIds,
      date: query.date
    })
    if (success) {
      await getOvertime()
      setShowAddNewModal(false)
      setLoader(false)
    }
    setLoader(false)
  }

  const handleResetEmployeeFilter = () => {
    setSearchValue({name: '', departmentId: undefined })
    setSearchResult(employeeData)
  }

  const handleCheckboxChange = () => {
    const newSelectionState = !allSelected;

    if (isSearch) {
      setSearchResult(prev => {
        return prev?.map(prevSub => {
          if (!prevSub.disabled) {
            return { ...prevSub, isSelected: newSelectionState };
          }
          return prevSub;
        });
      });
    } else {
      setEmployeeData(prev => {
        return prev?.map(prevSub => {
          if (!prevSub.disabled) {
            return { ...prevSub, isSelected: newSelectionState };
          }
          return prevSub;
        });
      });
    }
    setAllSelected(newSelectionState);
  };

  const handleDeleteOvertime = async () => {
    const service = new OvertimeService()
    setLoader(true)
    try {
      const success = await service.deleteOvertime([overtimeId])
      if (success) {
        swal({ className: 'rounded-swal', icon: 'success', timer: 700, buttons: [false] }).then(() => {
          setShowDeleteModal(false)
        })
        setFetchAgain(prev => !prev)
      }
    } catch (error) {
      console.error('Failed to delete overtime : ', error)
    } finally {
      setOvertimeId(0)
      setLoader(false)
    }
  }

  const handleShowMore = () => {
    if (totalData! / paginate.page <= paginate.limit) {
      return
    }
    setPaginate(prev => {
      return { ...prev, limit: prev.limit + 30 }
    })
  }

  useEffect(() => setTableData([]), [fetchAgain])
  useEffect(() => {
    ;(async () => {
      await getServiceIds()
      await getDepartments()
    })()
  }, [])

  useAsyncEffect(getOvertime, [paginate, fetchAgain])
  useAsyncEffect(getEmployees, [overtimes])

  return {
    val: {
      serviceIdSelected,
      tableColumns,
      tableSizes,
      tableData,
      tableIdIndex,
      departmentIds,
      departmentIdSelected,
      date,
      showDepartmentModal,
      departmentTableIdIndex,
      departmentTableData,
      departmentTableColumns,
      departmentTableSizes,
      showServiceModal,
      serviceTableIdIndex,
      serviceTableData,
      serviceTableColumns,
      serviceTableSizes,
      showAddNewModal,
      isSearch,
      searchValue,
      allSelected,
      showDeleteModal
    },
    set: {
      setServiceIdSelected,
      setQuery,
      setFetchAgain,
      setDepartmentIdSelected,
      setDate,
      setShowDepartmentModal,
      setShowServiceModal,
      setShowAddNewModal,
      setEmployeeData,
      setSearchResult,
      setIsSearch,
      setSearchValue,
      setShowDeleteModal
    },
    on: {
      handleModal,
      handleShowMore,
      handleCancelDepartmentModal,
      handleDepartmentsPopup,
      handleCancelServiceModal,
      handleServicePopup,
      handleSearch,
      handleSearchDepartment,
      handleAddEmployee,
      handleResetEmployeeFilter,
      handleCheckboxChange,
      handleDeleteOvertime
    },
    get: {
      query,
      serviceIds,
      paginate,
      totalData,
      searchResult,
      employeeData
    },
  }
}
