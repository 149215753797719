import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage';
import { SavedUser } from 'common/typing/saved-user.interface';
import responseHandler from 'common/helpers/axiosResponeHandler';

export class BaseService {
  accessToken: string;
  private isRefreshing: boolean = false;
  private refreshTokenPromise: Promise<any> | null = null;
  private failedQueue: { resolve: (value?: any) => void; reject: (reason?: any) => void; config: any }[] = [];

  constructor() {
    const user = Storage.get<SavedUser>('user');
    if (!user || !user.accessToken) {
      localStorage.clear();
      window.location.href = `${window.location.origin}/authenticate/login`;
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!);
  }

  private processQueue(error: any, token: string | null = null) {
    this.failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.config.headers['authorization'] = 'Bearer '.concat(token!);
        prom.resolve(ApiCaller(prom.config));
      }
    });
    this.failedQueue = [];
  }

  private async refreshToken(): Promise<void> {
    const user = Storage.get<SavedUser>('user');
    if (!user) {
      localStorage.clear();
      window.location.href = `${window.location.origin}/authenticate/login`;
      return;
    }

    if (!this.refreshTokenPromise) {
      this.refreshTokenPromise = new Promise(async (resolve, reject) => {
        try {
          const response = await ApiCaller({
            method: 'POST',
            url: process.env.REACT_APP_SERVER_URL!.concat('auth/refresh'),
            headers: { authorization: 'Bearer ' + user.refreshToken },
          });

          if (response.status === 201 && response.data.access_token) {
            this.accessToken = 'Bearer '.concat(response.data.access_token);
            user.accessToken = response.data.access_token;
            user.refreshToken = response.data.refresh_token;
            Storage.set<SavedUser>('user', user);

            this.processQueue(null, response.data.access_token);
            resolve(response.data.access_token);
          } else {
            this.processQueue('Refresh token failed', null);
            BaseService.dontAccept();
            reject('Refresh token failed');
            localStorage.clear();
            window.location.href = `${window.location.origin}/authenticate/login`;
          }
        } catch (err) {
          this.processQueue('Refresh token failed', null);
          BaseService.dontAccept();
          reject(err);
        } finally {
          this.isRefreshing = false;
          this.refreshTokenPromise = null;
        }
      });
    }

    return this.refreshTokenPromise;
  }

  protected async apiCallWithRefresh(fn: () => Promise<any>, config: any): Promise<any> {
    try {
      return await fn();
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          return new Promise((resolve, reject) => {
            this.failedQueue.push({ resolve, reject, config });
            this.refreshToken()
              .then(() => {
                config.headers['authorization'] = this.accessToken;
                resolve(ApiCaller(config));
              })
              .catch(err => reject(err));
          });
        } else {
          return new Promise((resolve, reject) => {
            this.failedQueue.push({ resolve, reject, config });
          });
        }
      } else {
        responseHandler(error, 'error', config?.customConfigs?.hidePopup);
        return error.response;
      }
    }
  }

  async callRefresh(config: IApiCallerConfig){
    return this.apiCallWithRefresh(async () => {
      return await ApiCaller(config)
    }, config);
  }

  static dontAccept() {
    throw 'not-acceptable';
  }
}
