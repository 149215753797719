import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'

export class CategoryImageSliderService extends BaseService{
  endpoint = 'category-image'

  constructor() {
    super();
  }

  async sliderImage(data: Lib.T.CategoryImageSlider.SliderImage,): Promise<{ success: boolean }> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data,
    }
    const response = this.callRefresh(config)
      const { status } = await response

      if (status !== 200) {
        return { success: false }
      }

      return { success: true }

  }

  async getSliderImages(id?: number): Promise<Lib.T.CategoryImageSlider.GetSliderImagesResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/category').concat(`/${id}`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return { success: true, images: data?.categoryImages }
      } else {
        return { success: false }
      }

  }

  async edit(args:any, id: number): Promise<Lib.T.CategoryImageSlider.EditReturnType> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/${id.toString()}`),
      headers: { authorization: this.accessToken },
      data: { order: args },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data) {
        return { success: false }
      }

      return { success: true }

  }

  async deleteSliderImage(id?: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/${id}`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200

  }
}
