import { BaseService } from './BaseService';
import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class DashboardService extends BaseService {
  endpoint = 'dashboard'

  constructor() {
    super();
  }

  async getAnnouncementsAndNews(filters?: Lib.T.Dashboard.GetListFilters): Promise<Lib.T.Dashboard.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/announcements`).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.announcements) {
        return { success: false }
      }
      return { success: true, announcements: data.announcements, news: data.announcements }
    
  }

  async getFoodList(): Promise<Lib.T.Dashboard.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/food-menu`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || (data && !data.foodItems)) {
        return { success: false }
      }
      return { success: true, foodList: data }
    
  }

  async getTodoList(todoListEndpoint: string, filters?: Lib.T.Dashboard.GetListFilters): Promise<Lib.T.Dashboard.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(todoListEndpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.result) {
        return { success: false }
      }
      return { success: true, todoList: data.result }
    
  }

  async putTodoList(todoListEndpoint: string, args: Lib.T.Dashboard.todoListArg, id: number): Promise<Lib.T.Dashboard.ListResult> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(todoListEndpoint).concat(id ? `/${id}` : ''),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (id) {
        if (status !== 200) {
          return { success: false }
        }
      } else {
        if (status !== 201) {
          return { success: false }
        }
      }

      return {
        success: true,
      }
    
  }

  async getTodayBirthdays(): Promise<Lib.T.Dashboard.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/today-birthdays`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data) {
        return { success: false }
      }
      return { success: true, todayBirthdays: data }
    
  }

  async getInWeekBirthdays(): Promise<Lib.T.Dashboard.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/in-week-birthdays`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data) {
        return { success: false }
      }
      return { success: true, inWeekBirthdays: data }
    
  }

  async getDrinks(): Promise<Lib.T.Dashboard.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat('automation/restaurant/drink'),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data) {
        return { success: false }
      }
      return { success: true, drinks: data }
    
  }

  async putDrink(arg: Lib.T.Dashboard.drinkArg): Promise<Lib.T.Dashboard.ListResult> {
    const config: IApiCallerConfig = {
      method: 'PUT',
      url: process.env.REACT_APP_SERVER_URL!.concat('automation/restaurant/drink'),
      headers: { authorization: this.accessToken },
      data: arg,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (arg) {
        if (status !== 200) {
          return { success: false }
        }
      } else {
        if (status !== 201) {
          return { success: false }
        }
      }

      return {
        success: true,
      }
    
  }

  async getCurrencyPrice(todoListEndpoint: string, filters?: Lib.T.Dashboard.GetListFilters): Promise<Lib.T.Dashboard.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(todoListEndpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.data) {
        return { success: false }
      }
      return { success: true, currencies: data.data }
    
  }

  async getWeather(todoListEndpoint: string, filters?: Lib.T.Dashboard.GetListFilters): Promise<Lib.T.Dashboard.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(todoListEndpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.data) {
        return { success: false }
      }
      return { success: true, weather: data.data }
    
  }
}
