import { BaseService } from './BaseService';
import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { IApiCallerConfig } from 'common/helpers/api-caller.helper'

export class AutomationCoffeeTime extends BaseService {
  endpoint = 'automation/restaurant/drink/coffee-time'

  constructor() {
    super();
  }

  async getDrinkTypes(): Promise<Lib.T.CoffeeTime.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data) {
        return { success: false }
      }
      return { success: true, coffeeTimes: data }
    
  }

  async deleteCoffeeTime(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
    
  }

  async createCoffeeTime(args: Lib.T.CoffeeTime.coffeeTimeArgs): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 201
    
  }
}
