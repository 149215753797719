import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import * as Lib from './lib'

export class FavoriteService extends BaseService{
  endpoint = 'favorite-products'

  constructor() {
    super();
  }

  async createFavorite(args: Lib.T.Favorite.CreateFavoriteProductArgs): Promise<Lib.T.Favorite.CreateFavoriteProductResponse> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 201) {
        return { success: false, favorite: null }
      }
      return { success: true, favorite: data.favoriteProducts }
    
  }

  async getFavorites(): Promise<Lib.T.Favorite.GetFavoriteProductResponse> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false, favorite: null }
      }
      return { success: true, favorite: data }
    
  }

  async deleteFavorite(): Promise<{ success: boolean }> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return { success: false }
      }
      return { success: true }
    
  }
}
