import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import swal from 'sweetalert'
import { DOM } from 'common/helpers/dom'

export class OptionService extends BaseService{
  type: Lib.T.Options.OptionTypes = 'stone-type'
  requestBody!: Lib.T.Options.MethodsArgs

  constructor({ type, ...data }: Lib.T.Options.ServiceArgs) {
    super();
    this.type = type

    const {
      country,
      countryMultiplier,
      areaId,
      gramMultiplier,
      karat,
      madeColor,
      maxPrice,
      minPrice,
      patent,
      productCategory,
      productCategoryImage,
      productCode,
      purity,
      size,
      stoneColor,
      stoneHeight,
      stoneLength,
      stoneShape,
      stoneType,
      stoneWidth,
      technicalColor,
      madeTitle,
      width,
      sizeInInches,
      property,
      goldPrice,
      customerRank,
      minWeight,
      maxWeight,
      customerRankImage,
    } = data

    switch (type) {
      case 'stone-size':
        this.requestBody = { stoneLength, stoneWidth, stoneHeight }
        break
      case 'stone-type':
        this.requestBody = { stoneType }
        break
      case 'stone-shape':
        this.requestBody = { stoneShape }
        break
      case 'stone-color':
        this.requestBody = { stoneColor }
        break
      case 'product-category':
        this.requestBody = { productCategoryImage, productCategory }
        break
      case 'product-code':
        this.requestBody = { productCode }
        break
      case 'country':
        this.requestBody = { country, countryMultiplier, areaId }
        break
      case 'necklace-size':
      case 'ring-size':
      case 'bracelet-size':
      case 'bangle-size':
      case 'pedant-size':
      case 'choker-size':
        this.requestBody = { size, sizeInInches }
        break
      case 'price-category':
        this.requestBody = { minPrice, maxPrice }
        break
      case 'made-color':
        this.requestBody = { madeColor, madeTitle }
        break
      case 'technical-color':
        this.requestBody = { technicalColor }
        break
      case 'karat':
        this.requestBody = { karat, purity, gramMultiplier }
        break
      case 'patent':
        this.requestBody = { patent }
        break
      case 'width':
        this.requestBody = { width }
        break
      case 'property':
        this.requestBody = { property }

        break
      case 'gold-price':
        this.requestBody = { goldPrice }

        break
      case 'customer-rank':
        this.requestBody = { customerRank, minWeight, maxWeight, customerRankImage }

        break

      default:
        OptionService.dontAccept()
    }
  }

  async create(): Promise<Lib.T.Options.CreateOrEditReturnType> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat('options'),
      headers: { authorization: this.accessToken },
      data: { ...this.requestBody, type: this.type },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 201) {
        return { success: false }
      }

      const optionData: Lib.T.Options.CreateDataType = data
      if (!optionData || !optionData.option) {
        return { success: false }
      }

      return {
        success: true,
        option: optionData.option,
      }
    
  }

  async edit(id: number): Promise<Lib.T.Options.CreateOrEditReturnType> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat('options/').concat(id.toString()),
      headers: { authorization: this.accessToken },
      data: { ...this.requestBody, type: this.type },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.option) {
        return { success: false }
      }

      return { success: true, option: data.option }
    
  }

  async read(args?: Lib.T.Options.ReadArgs): Promise<Lib.T.Options.ReadReturnType> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(
        `options?type=${this.type}&page=${args?.page || 1}&limit=${args?.limit || 99999999}${
          args?.productSubCategory ? `&productSubCategory=${args.productSubCategory}` : ''
        }`,
      ),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false, total: 0 }
      }

      const optionData: Lib.T.Options.ReadDataType = await data

      return {
        success: true,
        options: optionData.options,
        total: optionData.total,
      }
    
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`options/${id}`),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || data !== '') {
        return false
      }

      return true
    
  }

  async stoneProps(): Promise<Lib.T.Options.StonePropsReturn> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`options/stoneProperties`),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.options) {
        return { success: false }
      }

      const optionData: Lib.T.Options.StonePropsData = data
      const types: Lib.T.Options.StoneType[] = []
      const sizes: Lib.T.Options.StoneSize[] = []
      const colors: Lib.T.Options.StoneColor[] = []
      const shapes: Lib.T.Options.StoneShape[] = []
      const { options } = optionData

      options.forEach(item => {
        switch (item.type) {
          case 'stone-color':
            colors.push(item)
            break
          case 'stone-shape':
            shapes.push(item)
            break
          case 'stone-size':
            sizes.push(item)
            break
          case 'stone-type':
            types.push(item)
            break
        }
      })

      return {
        success: true,
        props: { types, sizes, colors, shapes },
      }
    
  }

  async getProductCategories(args?: Lib.T.Options.GetProductCategoriesArgs): Promise<Lib.T.Options.GetProductCategoriesResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`options/productCategories`).concat(DOM.objectToSearch(args ? args : {})),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return {
          success: true,
          data: data.productCategories,
          total: data.total,
        }
      }
      return { success: false, data: [], total: 0 }
    
  }

  static dontAccept() {
    throw 'not-acceptable'
  }
}
