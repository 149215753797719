import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { ModalProps } from 'antd/lib/modal'
import { ImageDetail } from 'common/components/ui-kit/image-list-slider/lib/typing'
import { SortableTypes } from 'common/components/ui-kit/table/lib/typing'
import { PIECES_EN } from 'common/constants/pieces'
import { doneTaskSuccessfully } from 'common/helpers/doneTaskSuccessfully'
import { useQuery } from 'common/helpers/getQueryParam'
import { numberInputTypeGuard } from 'common/helpers/numberInputTypeGuard'
import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import useMediaQuery from 'common/hooks/use-mediaQuery'
import { usePermissions } from 'common/routes/hooks'
import { RolesTRType } from 'common/routes/types/roles.type'
import { OptionService, ProductService, ProductSubCategory, SubPiecesService } from 'common/services'
import { FavoriteService } from 'common/services/favorite'
import { OptionTypes } from 'common/services/lib/types/options'
import { Piece } from 'common/services/lib/types/pieces'
import { FullProduct, ReadAllArgs, StoneType } from 'common/services/lib/types/product'
import { SubCategory } from 'common/services/lib/types/product-sub-category'
import { OrdersService } from 'common/services/orders'
import _ from 'lodash'
import { FillterDataType, FillterDataType as FilterDataType, ModalUpdateDataType as ModalUpdateDataType } from 'pages/private/orders/orderDetail/lib/typing'
import { Dispatch, FormEvent, SetStateAction, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import swal from 'sweetalert'
import * as Lib from '.'
import { PriceListType } from '../../pending-products/lib/types'
import * as C from './components'
import { FilterSelectStatus } from './components'

const productions: RolesTRType[] = [
  'Reçine Departmanı',
  'Döküm Departmanı',
  'Tezgah Departmanı',
  'Mine Departmanı',
  'Cila 1 Departmanı',
  'Cila 2 Departmanı',
  'Mağaza Departmanı',
]
const researchers: RolesTRType[] = ['Ar-Ge Müdürü', 'Ar-Ge']
const allRoles: RolesTRType[] = [...productions, ...researchers, 'Yönetim', 'Satış Yönetim', 'Üretim Yönetim', 'Satış Temsilcisi']

export const useTable = () => {
  const queryString = useQuery()

  const [showFilters, toggleShowFilters] = useState<boolean>(false)
  const [code, setCode] = useState<CheckboxValueType[]>([])
  const [piece, setPiece] = useState<CheckboxValueType[]>([])
  const [price, setPrice] = useState<CheckboxValueType[]>([])
  const [departments, setDepartments] = useState<CheckboxValueType[]>(['castingDepartment', 'rosinDepartment', 'projectDepartment'])
  const [onClickSubCategory, setOnClickSubCategory] = useState(0)
  const [categories, setCategories] = useState<any>([])
  const [subCategories, setSubCategories] = useState<SubCategory[]>([])
  const [subCategoriesPopup, setSubCategoriesPopup] = useState<SubCategory[]>([])
  const [weight, setWeight] = useState<[number, number]>([0, 0])
  const [profit, setProfit] = useState<[number | undefined, number | undefined]>([undefined, undefined])
  const [date, setDate] = useState<[string, string]>()
  const [subPiece, setSubPiece] = useState<Lib.T.SubPiecesObjType>({ lock: [], Kalem: 'Hepsi', bakla: [], chain: 'Hepsi', enamel: 'Hepsi' })
  const [stone, setStone] = useState<Lib.T.StoneObjType>({ color: [], shape: [], size: [], type: [] })
  const [drawing, setDrawing] = useState<Lib.T.DrawingObjType>({ modeler: [] })
  const [properties, setProperties] = useState<Lib.T.PropertiesObjType>({ properties: [] })
  const [selectProperty, setSelectProperty] = useState<boolean | null>(null)
  const [selectActive, setSelectActive] = useState<boolean | null>(null)
  const [selectDisabled, setSelectDisabled] = useState<boolean | null>(true)
  const [selectedName,setSelectedName] = useState<string | null>('')
  const [sort, setSort] = useState<SortableTypes>('NONE')
  const [gridData, setGridData] = useState<Lib.T.GridDataType[]>([])
  const [tableData, setTableData] = useState<Lib.T.TableData[]>([])

  const productCode = queryString.get('productCode')
  const fromNotification = queryString.get('fromNotification')

  const [totalData, setTotalData] = useState<number | undefined>()
  const [paginate, setPaginate] = useState({ limit: 80, page: 1 })
  const [paginateLoader, setPaginateLoader] = useState(false)
  const [gridView, setGridView] = useState<boolean>(true)
  const [productView, setProductView] = useState(false)
  const [subCategoryView, setSubCategoryView] = useState(false)
  const group = usePermissions()
  const stoneProfitPermissions: RolesTRType[] = ['Yönetim', 'Satış Yönetim', 'Üretim Yönetim']
  const stoneProfitCondition = stoneProfitPermissions.includes(group)
  const [query, setQuery] = useState<string>(productCode ?? '')
  const [queryFromNotification, setQueryFromNotification] = useState<string>(fromNotification ?? '')

  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const [fetchAgain, setFetchAgain] = useState<boolean>(true)
  const { setLoader } = useLoader()
  const [image, setImage] = useState<string>('')
  const [imageModal, setImageModal] = useState<boolean>(false)
  const [sortBy, setSortBy] = useState<Lib.T.SortTypes>('id')
  const [productDetailModal, setProductDetailModal] = useState<boolean>(false)
  const [monacoId, setMonacoId] = useState<number>(0)
  const [alreadyAddedProducts, setAlreadyAddedProducts] = useState<number[]>([])
  const [favoriteProductIds, setFavoriteProductIds] = useState<number[]>([])
  const [viewedFavoriteProductIds, setViewedFavoriteProductIds] = useState<number[]>([])
  const isFirstRender = useRef(true)
  const [isProductCodeSearch, setIsProductCodeSearch] = useState(false)

  const [modalForAllImages, setModalForAllImages] = useState<ImageDetail[]>([])

  const [showFilterModal, setShowFilterModal] = useState(false)

  const productCategoryIdQueryParam = queryString.get('productCategoryId')
  //filter categories states
  const [filterData, setFilterData] = useState<FilterDataType>({
    categories: [],
    subCategories: [],
    fetchSingleOrder: () => {},
    productCategorySelected: {
      isSelected: !!+productCategoryIdQueryParam!,
      productCategoryId: +productCategoryIdQueryParam! ?? 0,
      refreshProducts: true,
    },
    productSubCategorySelected: { isSelected: false, productSubCategoryId: 0 },
    isUpdate: false
  })

  const [modalUpdateData, setModalUpdateData] = useState<ModalUpdateDataType>({
    categories: [],
    subCategories: [],
    productCategorySelected: {
      isSelected: false,
      productCategoryId: 0,
    },
    productSubCategorySelected: { isSelected: false, productSubCategoryId: 0 },
    isUpdate: false,
    isPaginate: true
  })

  const isTV = useMediaQuery('(min-width: 2000px) and (min-height: 1200px)')

  const clearFilters = () => {
    setCode([])
    setPiece([])
    setPrice([])
    setDepartments([])
    setWeight([0, 0])
    setProfit([undefined, undefined])
    setSelectProperty(null)
    setSelectDisabled(true)
    setSelectActive(null)
    setProperties({ properties: [] })
    setDate(undefined)
    setQuery('')
    setQueryFromNotification('')
    setSubPiece({
      lock: [],
      Kalem: 'Hepsi',
      bakla: [],
      chain: 'Hepsi',
      enamel: 'Hepsi',
    })
    setStone({ color: [], shape: [], size: [], type: [] })
    setDrawing({ modeler: [] })
    setPaginate({ limit: 80, page: 1 })
    setFetchAgain(!fetchAgain)
    setSortBy('id')
    setSort('DESC')
    setDepartments(['castingDepartment', 'rosinDepartment', 'projectDepartment'])
    setShowFilterModal(false)
  }

  const openDetails = async (id: number) => {
    const orderService = new OrdersService()

    setLoader(true)
    const { success, error } = await orderService.addToCart({ productId: id })

    if (error?.statusCode === 400 && error?.message.includes('duplicate')) {
      swal({
        text: '',
        icon: 'error',
      })
      setFetchAgain(true)
      setLoader(false)
      return
    }

    if (success) {
      setFetchAgain(true)
      await fetchAllProducts(filterData.productCategorySelected.productCategoryId, filterData.productSubCategorySelected.productSubCategoryId)
      setLoader(false)
    }

    setLoader(false)
  }

  const fetchCategories = async () => {
    const service = new OptionService({ type: 'product-category' })
    const productCategory = new ProductSubCategory()
    const { data } = await productCategory.getList({ productCategory: 'monaco', limit: 999999999 })
    setMonacoId(data![0]?.parent?.id)
    const { success, options } = await service.read()
    if (success) {
      setCategories(options!)
      setFilterData(perval => ({ ...perval, categories: options! as any }))
      setModalUpdateData(perval => ({ ...perval, categories: options! as any }))
    }
  }

  const fetchSubCategories = async () => {
    const service = new ProductSubCategory()
    setLoader(true)
    const { success, data } = await service.getList()

    if (success && data!.length > 0) {
      const subCategoryOfMainCategory = data!.filter(cat => cat.parent.id === filterData.productCategorySelected.productCategoryId)
      setSubCategories(subCategoryOfMainCategory!)
      setFilterData(perval => ({ ...perval, subCategories: subCategoryOfMainCategory! as any }))

      setLoader(false)
    }
  }

  const fetchSubCategoriesPopup = async () => {
    const service = new ProductSubCategory()
    setLoader(true)
    const { success, data } = await service.getList()

    if (success && data!.length > 0) {
      const subCategoryOfMainCategory = data!.filter(cat => cat.parent.id === modalUpdateData.productCategorySelected.productCategoryId)
      setSubCategoriesPopup(subCategoryOfMainCategory!)
      setModalUpdateData(perval => ({ ...perval, subCategories: subCategoryOfMainCategory! as any }))
      setLoader(false)
    }
  }

  const fetchAllProducts = async (productCategoryId?: number, productSubCategoryId?: number) => {
    const service = new ProductService()

    if (paginateLoader) {
      setLoader(false)
    } else {
      setLoader(true)
    }

    const readAllArgs: ReadAllArgs = {
      limit: isTV ? 40 : paginate.limit,
      page: isTV ? paginate.page + 1 : (modalUpdateData.isUpdate ? 1 : paginate.page),
      modellerIds: drawing.modeler as number[],
      from: date ? (date[0] as string) : undefined,
      to: date ? (date[1] as string) : undefined,
      ...(numberInputTypeGuard(profit[0]) ? { minProfit: profit[0] } : null),
      ...(numberInputTypeGuard(profit[1]) ? { maxProfit: profit[1] } : null),
      ...(weight[0] ? { minWeight: weight[0] } : null),
      ...(weight[1] ? { maxWeight: weight[1] } : null),
      productSubCategoryId: productSubCategoryId,
      pieceTypes: piece ? piece as string[] : [],
      priceCategoryIds: price as number[],
      ...(isProductCodeSearch ? null : { keyword: query?.toUpperCase() }),
      ...(!isProductCodeSearch ? null : { productCode: query?.toUpperCase() }),
      productCodeTypeIds: code as number[],
      relatedDepartments: departments as string[],
      stoneColorIds: stone.color as number[],
      stoneShapeIds: stone.shape as number[],
      stoneSizeIds: stone.size as number[],
      stoneTypeIds: stone.type as number[],
      status: 'approved',
      propertyIds: properties.properties as number[],
      sortBy,
      hasProperty: selectProperty ?? undefined,
      showOnlyDisabled: selectedName === "Pasif" ? (!selectDisabled ?? undefined) : undefined,
      showOnlyActive: queryFromNotification ? undefined : (selectDisabled ? true : (selectedName === "Aktif" ? (selectDisabled ?? undefined) : undefined)),
      //showOnlyActive: true
    }

    if (sort !== 'NONE') {
      readAllArgs.sort = sort
    } else {
      readAllArgs.sort = 'DESC'
    }

    if (subPiece.Kalem === 'Kalemli') {
      readAllArgs.hasKalem = true
    } else if (subPiece.Kalem === 'Kalemsiz') {
      readAllArgs.hasKalem = false
    }

    if (subPiece.chain === 'Zincirle') {
      readAllArgs.hasChain = true
    } else if (subPiece.chain === 'Zincirsiz') {
      readAllArgs.hasChain = false
    }

    if (subPiece.enamel === 'Minele') {
      readAllArgs.hasEnamel = true
    } else if (subPiece.enamel === 'Minesiz') {
      readAllArgs.hasEnamel = false
    }

    const data = await service.readAll({ ...readAllArgs, productCategoryId: productCategoryId })

    setLoader(false)
    setPaginateLoader(false)
    const openImageModal = (img: string) => {
      setImage(img)
      setImageModal(true)
    }
    if (data.products) {
      setTotalData(parseInt(data.total!))
      setAlreadyAddedProducts(data.alreadyAddedProducts!)

      const set2 = new Set(data.viewedFavoriteProductsIds!)

      const uniqueToArr1 = data.favoriteProductIds!.filter(element => !set2.has(element))

      const uniqueToArr2 = data.viewedFavoriteProductsIds!.filter(element => !data.favoriteProductIds!.includes(element))

      const uniqueElements = [...uniqueToArr1, ...uniqueToArr2]

      setFavoriteProductIds(uniqueElements)
      setViewedFavoriteProductIds(data.viewedFavoriteProductsIds!)
      setIsProductCodeSearch(false)

      const tempGridData: Lib.T.GridDataType[] = []
      /*
          * productImage
            productImageLink
            productImageThumbnail
          * */
      const dataForImageModal = data.products.map((prod:any) => ({
        image: prod.productImage?.image,
        productImageThumbnail: prod.productImage?.imageThumbnail,
        productImageLink: prod.productImage?.imageLink,
        productCode: prod.productCode,
        weight: prod.weight.toString(),
        productId: prod.id,
      }))

      setModalForAllImages(perv => [...perv, ...dataForImageModal])

      const tableData = data.products.map((product:any) => {
        //@ts-ignore
        tempGridData.push({
          code: product.productCode,
          image: product.productImage?.image,
          productImageThumbnail: product.productImage?.imageThumbnail,
          productImageLink: product.productImage?.imageLink,
          weight: product.weight + '',
          id: product.id,
        })

        return {
          id: product.id,
          image: product.productImage?.image,
          productImageThumbnail: product.productImage?.imageThumbnail,
          productImageLink: product.productImage?.imageLink,
          imageOnClick: openImageModal,
          cardOnClick: openDetails,
          code: product.productCode,
          weight: product.weight,
          profit: product.stone_profit,
          status: product.status,
        }
      })

      setTableData(perv => [...perv, ...tableData])

      setGridData(prevData => [...prevData, ...tempGridData])
    }
  }

  const handleShowMore = () => {
    if (totalData! / paginate.page <= paginate.limit) {
      return
    }
    setPaginateLoader(true)
    setPaginate(perval => {
      return { ...perval, page: perval.page + 1 }
    })
  }

  const filterItems: Lib.T.FilterItemsType[] = [
    {
      title: 'Kod',
      children: <C.FilterCodes code={code} setCode={setCode} />,
      permissions: allRoles,
    },
    {
      title: 'Gram',
      children: <C.FilterWeight weight={weight} setWeight={setWeight} />,
      permissions: ['Yönetim', 'Satış Yönetim', 'Üretim Yönetim', 'Satış Temsilcisi'],
      maxHeight: 70,
    },
    {
      title: 'Parçalar',
      children: <C.FilterPieces piece={piece} setPiece={setPiece} />,
      permissions: allRoles,
      maxHeight: 135,
    },
    {
      title: 'Alt Parçalar',
      children: <C.FilterSubPrices subPiece={subPiece} setSubPiece={setSubPiece} />,
      maxHeight: 135,
      permissions: allRoles,
    },
    {
      title: 'Fiyat Grubu ',
      children: <C.FilterPrices price={price} setPrice={setPrice} />,
      permissions: ['Yönetim', 'Satış Yönetim', 'Üretim Yönetim'],
    },
    {
      title: 'Taş Karı',
      children: <C.FilterProfit profit={profit} setProfit={setProfit} />,
      permissions: ['Yönetim', 'Satış Yönetim', 'Üretim Yönetim'],
      maxHeight: 70,
    },
    {
      title: 'Taşlar',
      children: <C.FilterStone stone={stone} setStone={setStone} />,
      maxHeight: 180,
      permissions: allRoles,
    },
    {
      title: 'Modelleyen Kişi',
      children: <C.FilterDrawing drawing={drawing} setDrawing={setDrawing} />,
      maxHeight: 280,
      permissions: ['Yönetim', 'Satış Yönetim', 'Üretim Yönetim', 'Ar-Ge Müdürü'],
    },
    {
      title: 'Kriter',
      children: <C.FilterProperties properties={properties} setProperties={setProperties} />,
      maxHeight: 135,
      permissions: ['Yönetim', 'Satış Yönetim', 'Üretim Yönetim', 'Ar-Ge Müdürü', 'Satış Temsilcisi'],
    },
    {
      title: 'Kriter Seç',
      children: <C.FilterSelectProperty selectProperty={selectProperty} setSelectProperty={setSelectProperty} />,
      maxHeight: 105,
      permissions: ['Yönetim', 'Satış Yönetim', 'Üretim Yönetim', 'Ar-Ge Müdürü', 'Satış Temsilcisi'],
    },
    {
      title: 'Tarih',
      children: <C.FilterDate date={date} setDate={setDate} />,
      permissions: allRoles,
      maxHeight: 60,
    },
    {
      title: 'İlgili Departman',
      children: <C.FilterDepartment department={departments} setDepartment={setDepartments} />,
      borderBottom: false,
      permissions: allRoles,
      maxHeight: 120,
    },
    {
      title: 'Durum',
      children: <C.FilterSelectStatus selectActive={selectActive} setSelectedName={setSelectedName} setSelectActive={setSelectActive} selectDisabled={selectDisabled} setSelectDisabled={setSelectDisabled} />,
      maxHeight: 75,
      permissions: ['Yönetim', 'Satış Yönetim', 'Üretim Yönetim'],
    },
  ]

  const onFetchAgain = () => {
    setModalForAllImages([])
    setTableData([])
    setGridData([])
    setShowFilterModal(false)
  }

  const imageModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: imageModal,
    onCancel: () => setImageModal(false),
    className: 'noHeaderModal scrollWidthModal',
  }
  const productDetailModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: productDetailModal,
    onCancel: () => setProductDetailModal(false),
    width: 1200,
    style: { height: 700, top: 20 },
  }
  const onClickToSort = (column: Lib.T.SortTypes, nextStatus: SortableTypes) => {
    setPaginate(perv => ({ ...perv, page: 1 }))

    setSort(nextStatus)
    setSortBy(column)
  }

  useEffect(onFetchAgain, [fetchAgain, sortBy, sort, onClickSubCategory])
  useAsyncEffect(fetchCategories, [])

  useEffect(() => {
      if (filterData.productCategorySelected.refreshProducts || !modalUpdateData.isPaginate) {
        fetchAllProducts(filterData.productCategorySelected.productCategoryId, filterData.productSubCategorySelected.productSubCategoryId)
        setModalUpdateData( perv => ({ ...perv,
          isUpdate: false
        }))
      }
  }, [
    paginate,
    sortBy,
    sort,
    filterData.productCategorySelected.productCategoryId,
    filterData.productSubCategorySelected.productSubCategoryId,
    filterData.productCategorySelected.refreshProducts,
    isTV,
  ])

  // useEffect(() => {
  //   if (!modalUpdateData.isPaginate) {
  //     setPaginate({limit: 80, page: 1})
  //     fetchAllProducts(filterData.productCategorySelected.productCategoryId, filterData.productSubCategorySelected.productSubCategoryId)
  //     setModalUpdateData( perv => ({ ...perv,
  //       isPaginate: true
  //     }))
  //     setFilterData(perval => ({ ...perval,
  //       productCategorySelected: { ...perval.productCategorySelected, refreshProducts: true }}))
  //   }
  // }, [paginate])

  useEffect(() => {
    productView && fetchAllProducts(filterData.productCategorySelected.productCategoryId, filterData.productSubCategorySelected.productSubCategoryId)
    setProductView(false)
  }, [productView])

  useEffect(() => {
    if (modalUpdateData.isUpdate) {
      setGridData([])
      setPaginate({limit: 80, page: 1})
      // setTimeout( () =>{
      //   fetchAllProducts(filterData.productCategorySelected.productCategoryId, filterData.productSubCategorySelected.productSubCategoryId)
      // },1000)
      // setModalUpdateData( perv => ({ ...perv,
      //   productCategorySelected: { ...perv.productCategorySelected, productCategoryId: filterData.productCategorySelected.productCategoryId || 0 },
      //   //isUpdate: false
      // }))
    }
  }, [modalUpdateData.isUpdate])

  const createFavoriteProduct = async (productId: number, create: boolean) => {
    const service = new FavoriteService()
    if (favoriteProductIds.length >= 12 && create) {
      toast('En fazla 12 tane ürün ekleyebilirsiniz.')
      return
    }
    setLoader(true)
    const { success, favorite } = await service.createFavorite({ productId, create })
    if (create) {
      setFavoriteProductIds(perval => [...perval, productId])
    } else {
      setFavoriteProductIds(perval => perval.filter(p => p !== productId))
    }
    setLoader(false)
  }
  const deleteFavoriteProduct = async () => {
    const service = new FavoriteService()
    setLoader(true)
    await service.deleteFavorite()
    setViewedFavoriteProductIds([])
    setFavoriteProductIds([])
    setLoader(false)
  }

  const filterModalProps: ModalProps = {
    footer: null,
    title: <span style={{fontWeight: 'bold'}}>Filtrele</span>,
    visible: showFilterModal,
    onCancel: () => setShowFilterModal(false),
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      setModalForAllImages([])
      setTableData([])
      setGridData([])
    }
  }, [filterData.productCategorySelected.productCategoryId, filterData.productSubCategorySelected.productSubCategoryId])

  useAsyncEffect(fetchSubCategories, [filterData.productCategorySelected.productCategoryId])
  useAsyncEffect(fetchSubCategoriesPopup, [modalUpdateData.productCategorySelected.productCategoryId])
  return {
    get: {
      filterData,
      modalUpdateData,
      searchInputRef,
      tableData,
      gridData,
      filterModalProps,
      gridView,
      group,
      stoneProfitCondition,
      totalData,
      paginate,
      paginateLoader,
      showFilters,
      filterItems,
      query,
      queryFromNotification,
      fetchAgain,
      subCategoryView,
      sortBy,
      sort,
      subCategories,
      subCategoriesPopup,
      categories,
      productView,
      monacoId,
      imageModal,
      alreadyAddedProducts,
      modalForAllImages,
      favoriteProductIds,
      viewedFavoriteProductIds,
      code,
      weight,
      piece,
      subPiece,
      price,
      profit,
      stone,
      drawing,
      properties,
      selectProperty,
      date,
      departments,
      selectActive,
      selectDisabled,
      selectedName
    },
    set: {
      setGridView,
      setModalForAllImages,
      setIsProductCodeSearch,
      setImageModal,
      setFilterData,
      setModalUpdateData,
      setPaginate,
      toggleShowFilters,
      setQuery,
      setQueryFromNotification,
      setFetchAgain,
      setSortBy,
      setSort,
      setSubCategoryView,
      setProductView,
      setOnClickSubCategory,
      setAlreadyAddedProducts,
      setFavoriteProductIds,
      setViewedFavoriteProductIds,
      setShowFilterModal,
    },
    on: {
      handleShowMore,
      clearFilters,
      onClickToSort,
      fetchSubCategories,
      openDetails,
      createFavoriteProduct,
      deleteFavoriteProduct,
      fetchAllProducts,
    },
    modals: {
      imageModal: {
        props: imageModalProps,
        toggle: () => setImageModal(!imageModal),
        image: { val: image, set: setImage },
      },
      productModal: {
        props: productDetailModalProps,
        toggle: () => setProductDetailModal(!productDetailModal),
      },
    },
  }
}

export const useMonacoAddToCart = () => {
  const [showModal, toggleShowModal] = useState<boolean>(false)
  const [necklace, setNecklace] = useState<Lib.T.DropDownValues>({ size: 45, color: 'B', mine: 'Mor' })
  const [ring, setRing] = useState<Lib.T.DropDownValues>({ size: 14, color: 'B', mine: '' })
  const [bracelet, setBracelet] = useState<Lib.T.DropDownValues>({ size: 19, color: 'B', mine: 'Beyaz' })

  const pieceStates: Lib.T.PiecesStates = {
    necklace: { value: necklace, setValue: setNecklace },
    ring: { value: ring, setValue: setRing },
    bracelet: { value: bracelet, setValue: setBracelet },
  }

  const modalProps: ModalProps = {
    footer: null,
    title: null,
    visible: showModal,
    onCancel: () => toggleShowModal(false),
    width: '80%',
    className: 'noHeaderModal scrollWidthModal',
  }

  const pieces: Lib.T.TableRows[] = []

  const addToCart = () => alert('added')

  return {
    modalProps,
    toggleShowModal,
    pieces,
    pieceStates,
    addToCart,
  }
}

export const useDetailNumbers = ({ defaultValue, onChange }: Pick<Lib.T.DetailNumbersProps, 'defaultValue' | 'onChange'>) => {
  const [number, setNumber] = useState<number>(defaultValue)
  const NUMBERS = [0, 1, 2, 3, 4]

  const getValueFromInput = (evt: FormEvent<HTMLInputElement>) => {
    const value = parseInt(evt.currentTarget.value)
    const initialValue = value

    setNumber(initialValue)
    if (onChange) {
      onChange(initialValue)
    }
  }

  const getValueFromItems = (num: number) => {
    setNumber(num)
    if (onChange) {
      onChange(num)
    }
  }
  useEffect(() => {
    setNumber(defaultValue)
  }, [defaultValue])
  return {
    NUMBERS,
    number,
    getValueFromInput,
    getValueFromItems,
  }
}

export const useCommonAddToCart = () => {
  const [showModal, toggleShowModal] = useState<boolean>(false)
  const [addToCartSizes, setAddToCartSizes] = useState<Lib.T.AddToCartStates[]>([])
  const [addToCartColors, setAddToCartColors] = useState<Lib.T.AddToCartStates[]>([])
  const [addToCartEnamels, setAddToCartEnamels] = useState<Lib.T.AddToCartStates[]>([])
  const [statesContainer, setStatesContainer] = useState<Lib.T.AddToCartStatesContainer[]>([])
  const [selectedProductId, setSelectedProductId] = useState<number>(0)
  const { setLoader } = useLoader()

  const translatePieceType = (type: PIECES_EN): OptionTypes | false => {
    switch (type) {
      case 'necklace':
        return 'necklace-size'
      case 'ring':
        return 'ring-size'
      case 'cuff':
      case 'bracelet':
        return 'bracelet-size'
      case 'ear-rings':
        return false
      case 'pendant':
        return false
    }
  }

  const getAddToCartSizes = async (type: OptionTypes) => {
    const optionService = new OptionService({ type })
    const { options, success } = await optionService.read({ limit: 99999999 })
    if (!success || !options) {
      return false
    } else {
      return options
    }
  }

  const modalProps: ModalProps = {
    footer: null,
    title: null,
    visible: showModal,
    onCancel: () => {
      toggleShowModal(false)
    },
    width: '90%',
    className: 'noHeaderModal scrollWidthModal',
  }

  const getAddToCartColors = async () => {
    const optionService = new OptionService({ type: 'made-color' })
    const { success, options } = await optionService.read({
      limit: 99999999,
    })
    if (!success || !options) {
      return
    }
    setAddToCartColors(
      options.map(o => {
        return {
          id: o.id,
          title: o.madeTitle!,
        }
      }),
    )
    setStatesContainer(prev => {
      return prev.map(i => {
        return {
          ...i,
          color: options[0] && options[0].id,
        }
      })
    })
  }

  const getAddToCartEnamels = async () => {
    const subPiecesService = new SubPiecesService('enamels')
    const { success, data } = await subPiecesService.read<{
      enamels: { id: number; color: string }[]
    }>(`limit=9999999`)
    if (!success || !data) {
      return
    }
    setAddToCartEnamels(
      data.enamels.map(e => {
        return {
          id: e.id,
          title: e.color,
        }
      }),
    )
  }

  const onShowModalToggle = () => {
    if (!showModal) {
      return
    }
    getAddToCartColors()
    if (addToCartEnamels.length < 1) {
      getAddToCartEnamels()
    }
  }

  useEffect(onShowModalToggle, [showModal])
  return {
    modalProps,
    toggleShowModal,
    showModal,
    get: {
      addToCartEnamels,
      addToCartSizes,
      addToCartColors,
      statesContainer,
      selectedProductId,
    },
    set: {
      setAddToCartSizes,
      setStatesContainer,
      setSelectedProductId,
    },
    on: {
      translatePieceType,
      getAddToCartSizes,
    },
  }
}
export const useDetails = (
  setFetchAgain: Dispatch<SetStateAction<boolean>>,
  categories?: Lib.T.ProductCategory[],
  subCategories?: Lib.T.ProductSubCategory[],
  toggleModal?: () => void,
  triggerFetchSubCategories?: Dispatch<SetStateAction<FillterDataType>> | undefined,
  triggerFetchModalUpdate?: Dispatch<SetStateAction<ModalUpdateDataType>> | undefined,
  subCategoriesPopup?: Lib.T.ProductSubCategory[],
) => {
  const [productDetail, setProductDetail] = useState<FullProduct>()
  const [productDetailAllData, setProductDetailAllData] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [productId, setProductId] = useState<number>()
  const [allPieces, setAllPieces] = useState<Piece[]>([])
  const [piecesNumber, setPiecesNumber] = useState<number>(0)
  const [filteredPieces, setFilteredPieces] = useState<Piece[]>([])
  const [pieces, setPieces] = useState<Piece[]>([])
  const [stones, setStones] = useState<StoneType[]>()
  const [allStones, setAllStones] = useState<StoneType[]>([])
  const [stoneNumber, setStoneNumber] = useState<number>(0)
  const [showPriceModal, setShowPriceModal] = useState(false)
  const [priceList, setPriceList] = useState<PriceListType[]>()
  const [priceId, setPriceId] = useState(0)
  const [priceRange, setPriceRange] = useState('')
  const [karats, setKarats] = useState<{ id: number; title: string }[]>([])
  const [karat, setKarat] = useState<string>()

  // property States
  const [propertyModalShow, setPropertyModalShow] = useState(false)
  const [propertyList, setPropertyList] = useState<{ id: string | number; title: string }[] | undefined>([])
  const [properties, setProperties] = useState<number[]>([])

  // edit category and subCategory states
  const [categoriesForDropDown, setCategoriesForDropDown] = useState<Lib.T.DropDownType>()
  const [subCategoriesForDropDown, setSubCategoriesForDropDown] = useState<Lib.T.DropDownType>()
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>()
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<number>()

  // disable product states

  const [disableModal, setDisableModal] = useState(false)

  useEffect(() => {
    const transformedCategories = categories?.map(cat => ({ id: cat.id, title: cat.productCategory }))
    setCategoriesForDropDown(transformedCategories)
  }, [categories])

  useEffect(() => {
    const transformedSubCategories = subCategoriesPopup?.map(cat => ({ id: cat.id, title: cat.productSubCategory }))
    setSubCategoriesForDropDown(transformedSubCategories)
  }, [subCategoriesPopup])

  useEffect(() => {
    setSelectedCategoryId(productDetail?.productCategory.id)
    setSelectedSubCategoryId(productDetail?.productSubCategory.id)
  }, [productDetail])

  async function handleDisableProduct(state: boolean) {
    // if (!productId) return
    const service = new ProductService()

    try {
      setLoader(true)
      await service.disableProduct(productId!, state)
      setFetchAgain(perv => !perv)
      setDisableModal(false)
      toggleModal && toggleModal()

      triggerFetchSubCategories &&
        triggerFetchSubCategories(perv => ({
          ...perv,
          productCategorySelected: { ...perv.productCategorySelected, refreshProducts: false },
        }))
      triggerFetchSubCategories &&
        triggerFetchSubCategories(perv => ({
          ...perv,
          productCategorySelected: { ...perv.productCategorySelected, refreshProducts: true },
        }))

      setLoader(false)
    } catch (e) {
      setLoader(false)
    }
  }

  const { setLoader } = useLoader()

  const DetailModalVisibleHandler = () => {
    setIsDetailModalVisible(perval => !perval)
  }

  const handleCancelDetailModal = () => {
    setIsDetailModalVisible(false)
  }

  const ConfirmModalVisibleHandler = () => {
    setIsConfirmModalVisible(perval => !perval)
  }

  const handleCancelConfirmModal = () => {
    setIsConfirmModalVisible(false)
  }

  const handleDeleteProduct = async (id: number) => {
    const service = new ProductService()
    const isProductDeleted = await service.delete(id)

    if (isProductDeleted) {
      doneTaskSuccessfully().then(() => {
        handleCancelConfirmModal()
        if (toggleModal) {
          toggleModal()
        }
        setFetchAgain(perv => !perv)
      })
    } else {
      swal({
        text: 'Bu ürün bir siparişte kullanılmıştır ve silinemez.',
        icon: 'error',
      })
    }
  }

  const fetchProductDetail = async () => {
    //setProductDetail(undefined)
    setFilteredPieces([])
    setAllPieces([])
    setAllStones([])
    setPieces([])
    setStones([])
    setStoneNumber(0)
    setPiecesNumber(0)
    if (productId) {
      const service = new ProductService()

      karat ? setLoader(false) : setLoader(true)

      await service
        .findOne(productId, karat ? karat : '')
        .then(result => {
          if (result.data) {
            setProductDetailAllData(result.allData)
            setProductDetail(result.data)
            setAllPieces(result.data.pieces)
            setPieces(result.data.pieces)
            setPriceId(result.data.priceCategory?.id)
            setPriceRange(`[ ${result.data.priceCategory?.minPrice || 0} , ${result.data.priceCategory?.maxPrice || 0} ]`)
            const dataNeededForProperties = result.data.properties.map(prop => prop.id)
            setProperties(dataNeededForProperties)
          }
          if (result.stones) {
            setAllStones(result.stones)
          }
        })
        .catch(e => {
          swal({
            text: 'e.message',
            icon: 'error',
            timer: 3000,
          })
        })
        .finally(() => {
          setLoader(false)
          setLoading(false)
          slicePieces(0)
          sliceStones(0)
        })
    }
  }

  const updatePrice = async () => {
    const service = new ProductService()

    const { success, priceCategory } = await service.edit({ priceCategory: priceId }, productId!)

    if (success) {
      swal({
        buttons: [false],
        text: '',
        icon: 'success',
        className: 'rounded-swal',
        timer: 1000,
      }).then(() => {
        setPriceRange(`[ ${priceCategory?.minPrice} , ${priceCategory?.maxPrice} ]`)
        setShowPriceModal(false)
      })
    }
  }

  const fetchPropertyList = async () => {
    const service = new OptionService({ type: 'property' })

    const { success, options } = await service.read()

    if (!success) {
      return swal({ text: 'something went wrong' })
    }

    const neededData = options?.map(opt => ({ title: opt.property!, id: opt.id }))

    setPropertyList(neededData)
  }

  const updateProperty = async () => {
    const service = new ProductService()
    if (productId) {
      if (!selectedSubCategoryId) {
        return swal({
          text: 'lütfen alt kategori seçiniz.',
          icon: 'info',
        })
      }

      const { success, product } = await service.edit(
        { properties, productCategory: selectedCategoryId, productSubCategory: selectedSubCategoryId },
        productId,
      )

      const dataNeededForProperties = product?.properties.map(prop => prop.id)
      if (dataNeededForProperties?.length) setProperties(dataNeededForProperties)

      if (success) {
        setLoader(false)

        swal({
          icon: 'success',
          timer: 500,
          className: 'rounded-swal',
          buttons: [false],
        }).then(() => {
          setProductDetail(product!)
          setPropertyModalShow(false)
        })
      }
      setLoader(false)
    }
  }

  const fetchPrices = async () => {
    const service = new OptionService({ type: 'price-category' })

    const { success, options } = await service.read()
    if (success) {
      const list = options?.map(opt => {
        return { id: opt.id, title: `[ ${opt.minPrice} , ${opt.maxPrice} ]` }
      })
      setPriceList(list)
    }
  }

  const fetchKarat = async () => {
    const service = new OptionService({ type: 'karat' })

    const { success, options } = await service.read()

    if (success) {
      const karat = options?.map(option => {
        return {
          id: option.id,
          title: option.karat!,
        }
      })
      setKarats(karat!)
    }
  }

  const sliceStones = (number: number, data?: StoneType[]) => {
    if (allStones.length > 0) {
      const currentStones: StoneType[] = _.slice(allStones, number, stoneNumber + 2)
      setStones(currentStones)
    } else if (data?.length) {
      const currentStones = _.slice(data, number, stoneNumber + 2)
      setStones(currentStones)
    }
  }
  const nextStone = () => {
    const totalPages = Math.ceil(allPieces?.length / 4)
    if (stoneNumber + 1 > totalPages) {
      slicePieces(stoneNumber + 1)
      setStoneNumber(stone => stone + 1)
    }
  }
  const previousStone = () => {
    if (stoneNumber >= 1) {
      sliceStones(stoneNumber - 1)
      setStoneNumber(stone => stone - 1)
    } else {
      sliceStones(0)
    }
  }
  const slicePieces = (number: number, data?: Piece[]) => {
    if (allPieces.length > 0) {
      const currentPieces = _.slice(allPieces, number, piecesNumber + 3)
      setFilteredPieces(currentPieces)
    } else if (data?.length) {
      const currentPieces = _.slice(data, number, piecesNumber + 3)
      setFilteredPieces(currentPieces)
    }
  }
  const nextPieces = () => {
    if (piecesNumber + 1 > allPieces.length) {
      slicePieces(piecesNumber + 1)
      setPiecesNumber(pieces => pieces + 1)
    }
  }
  const previousPieces = () => {
    if (piecesNumber > 1) {
      slicePieces(piecesNumber - 1)
      setPiecesNumber(pieces => pieces - 1)
    } else {
      slicePieces(0)
    }
  }

  const disableModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: disableModal,
    onCancel: () => {
      setDisableModal(false)
      triggerFetchSubCategories &&
        triggerFetchSubCategories(perv => ({
          ...perv,
          productCategorySelected: { ...perv.productCategorySelected, refreshProducts: false },
        }))
    },
    className: 'noHeaderModal',
  }

  const priceModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: showPriceModal,
    afterClose: () => {},
    onCancel: () => setShowPriceModal(false),
    className: 'noHeaderModal',
  }

  const propertyModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: propertyModalShow,
    onCancel: () => {
      setPropertyModalShow(false)
      setSelectedCategoryId(productDetail?.productCategory.id)
      setSelectedSubCategoryId(productDetail?.productSubCategory.id)
      setProperties(productDetail?.properties.map(prop => prop.id)!)
    },
    className: 'noHeaderModal',
  }

  useEffect(() => {
    fetchProductDetail()
  }, [productId, karat])
  useEffect(() => {
    sliceStones(stoneNumber)
  }, [allStones])
  useEffect(() => {
    slicePieces(piecesNumber)
  }, [allPieces])

  useEffect(() => {
    fetchPrices()
    fetchPropertyList()
    fetchKarat()
  }, [])

  return {
    val: {
      productId,
      filteredPieces,
      allPieces,
      allStones,
      piecesNumber,
      stoneNumber,
      karats,
      karat
    },
    get: {
      priceModalProps,
      disableModalProps,
      selectedSubCategoryId,
      subCategoriesForDropDown,
      productDetailAllData,
      disableModal,
      showPriceModal,
      priceId,
      productDetail,
      priceRange,
      loading,
      properties,
      DetailModalVisibleHandler,
      handleCancelDetailModal,
      ConfirmModalVisibleHandler,
      handleCancelConfirmModal,
      isDetailModalVisible,
      isConfirmModalVisible,
      handleDeleteProduct,
      setProductId,
      nextPieces,
      previousPieces,
      priceList,
      filteredPieces,
      pieces,
      stones,
      nextStone,
      previousStone,
      propertyModalProps,
      propertyList,
      categoriesForDropDown,
      selectedCategoryId,
    },
    set: {
      setShowPriceModal,
      setDisableModal,
      setSelectedSubCategoryId,
      setPriceId,
      setProperties,
      setPropertyModalShow,
      setSelectedCategoryId,
      setKarats,
      setKarat
    },
    on: {
      updatePrice,
      updateProperty,
      handleDisableProduct,
    },
  }
}
