import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { BaseService } from './BaseService'
import { IApiCallerConfig } from 'common/helpers/api-caller.helper'

export class AutomationDepartmentService extends BaseService {
  endpoint = 'automation/department'

  constructor() {
    super()
  }

  async getList(): Promise<Lib.T.AutomationDepartments.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
    const { status, data } = await response
    if (status !== 200 || !data.departments) {
      return { success: false, total: 0 }
    }
    return { success: true, total: data.total, data: data.departments }
  }

  async create(args: Lib.T.AutomationDepartments.DepartmentArgs): Promise<Lib.T.AutomationDepartments.ListResult> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    }

    const response = this.callRefresh(config)
    const { status } = await response
    if (status !== 201) {
      return { success: false }
    }

    return { success: true, total: 0 }
  }

  async edit(args: Lib.T.AutomationDepartments.DepartmentArgs, id: number): Promise<Lib.T.AutomationDepartments.ListResult> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    }

    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return { success: false }
      }

      return { success: true, total: 0 }
  }

  async delete(id: number): Promise<Lib.T.AutomationDepartments.ListResult> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return { success: false }
      }

      return { success: true, total: 0 }
  }

  async findOne(id: number): Promise<any> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
    const { status, data } = await response
    if (status === 200) {
      return {
        success: true,
        department: data,
      }
    }
    return {
      success: false,
    }
  }
}
