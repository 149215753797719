import { Content } from 'antd/lib/layout/layout'
import Modal from 'antd/lib/modal/Modal'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Icon } from 'common/components/ui-kit/icon'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from './lib'
import { useOvertime } from './lib/hooks'
import { Sticky } from 'common/components/ui-kit/sticky'
import { DropDown2 } from 'common/components/ui-kit/dropdown2'
import { Table } from 'common/components/ui-kit/table-2'
import { DatePicker } from 'antd'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/tr_TR'
import React from 'react'
import { Checkbox } from '../../../../common/components/ui-kit/checkbox'
import { SelectAllContent } from './lib/styles'

function Overtime() {
  const { val, set, on, get } = useOvertime()

  return (
    <PanelWrapper tab={['automation', '/automation/overtime']}>
      <Sticky>
        <Lib.S.searchSection>
          <p className="title">Mesai</p>
          <div>
            <div className="inputContainer" style={{maxWidth: 300}}>
              <Input
                type="search"
                placeholder="Ad ile Ara..."
                lessBorder
                value={get.query.name}
                onInput={value => set.setQuery({ ...get.query, name: value.currentTarget.value })}
                onEnter={() => set.setFetchAgain(perv => !perv)}
                containerStyles={{height: 40}}
                inputStyles={{height: 40}}
              />
            </div>
            <Button
              className="buttonContainer"
              icon="search"
              mood="accent"
              callback={() => set.setFetchAgain(perv => !perv)}
              iconProps={{ color: 'white', size: 20 }}
              containerStyles={{height: 40}}
            />
          </div>

          <DropDown2
            className="dropdown"
            options={val.departmentIds}
            label=""
            height="40px"
            value={get.query.departmentId}
            placeholder="Departman Seç"
            onChange={value => {
              set.setQuery({ ...get.query, departmentId: value })
              set.setFetchAgain(perv => !perv)
            }}
            containerStyles={{maxWidth: 150}}
          />

          <DropDown2
            className="dropdown"
            options={get.serviceIds}
            label=""
            height="40px"
            value={get.query.serviceId}
            placeholder="Servis Seç"
            onChange={value => {
              set.setQuery({ ...get.query, serviceId: value })
              set.setFetchAgain(perv => !perv)
            }}
            containerStyles={{maxWidth: 150}}
          />

          <div style={{ maxWidth: 150 }}>
            <DatePicker
              value={get.query.date ? moment(get.query.date, 'YYYY-MM-DD') : null}
              onChange={(date: moment.Moment | null) => {
                if (date) {
                  const formattedDateForServer = date.format('YYYY-MM-DD')
                  set.setQuery({ ...get.query, date: formattedDateForServer })
                  set.setFetchAgain(prev => !prev)
                } else {
                  set.setQuery({ ...get.query })
                  set.setFetchAgain(prev => !prev)
                }
              }}
              size="small"
              locale={locale}
              allowClear={false}
              className="w-100"
              style={{ height: 40, borderRadius: 7 }}
              inputReadOnly
              format="DD-MM-YYYY"
            />
          </div>

          <div className="d-flex justify-content-start">
            <Button
              mood="accent"
              callback={() => {
                set.setQuery({ departmentId: undefined, serviceId: undefined, name: '', date: '' })
                set.setFetchAgain(perv => !perv)
              }}
              text="Filtreyi Temizle"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              containerStyles={{ minHeight: 40, width: 130 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />
          </div>
          <div className="d-flex justify-content-end gap-2">
            <Button
              mood="accent"
              callback={() => set.setShowAddNewModal(true)}
              text="Ekle"
              icon="plus"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              containerStyles={{ minHeight: 40, width: 80 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />
            <Button
              mood="accent"
              callback={() => on.handleServicePopup()}
              text="Servis listesi"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              containerStyles={{ minHeight: 40, width: 150 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />
            <Button
              mood="accent"
              callback={() => on.handleDepartmentsPopup()}
              text="Departman listesi"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              containerStyles={{ minHeight: 40, width: 150 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />
          </div>
        </Lib.S.searchSection>
      </Sticky>
      <Content>
        <Lib.S.PageWrapper>
          <Lib.S.content>
            <div className="tableContainer">
              <Table
                columns={val.tableColumns}
                columnSizes={val.tableSizes}
                data={val.tableData}
                onLoadMore={on.handleShowMore}
                totalData={get.totalData}
                paginate={get.paginate}
                rowBackColor={false}
                disabledIndexes={[val.tableIdIndex, val.tableIdIndex - 1]}
                maxHeight="calc(100vh - 110px)"
              />
            </div>
          </Lib.S.content>
        </Lib.S.PageWrapper>

        {/* Departman modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showDepartmentModal}
          onCancel={on.handleCancelDepartmentModal}
          className="noHeaderModal userModal"
          width={700}
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>Departman</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={on.handleCancelDepartmentModal} style={{ cursor: 'pointer' }} />
            </div>
            <div className="px-3">
              <Lib.S.content>
                <div className="tableContainer">
                  <Table
                    columns={val.departmentTableColumns}
                    columnSizes={val.departmentTableSizes}
                    data={val.departmentTableData}
                    //onLoadMore={on.handleShowMore}
                    //totalData={get.totalData}
                    //paginate={get.paginate}
                    disabledIndexes={[val.departmentTableIdIndex, val.departmentTableIdIndex - 1]}
                    contentStyle={{background: '#fff'}}
                  />
                </div>
              </Lib.S.content>
            </div>
          </Lib.S.ModalContainer>
        </Modal>

        {/* Servis modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showServiceModal}
          onCancel={on.handleCancelServiceModal}
          className="noHeaderModal userModal"
          width={700}
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>Servis</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={on.handleCancelServiceModal} style={{ cursor: 'pointer' }} />
            </div>
            <div className="px-3">
              <Lib.S.content>
                <div className="tableContainer">
                  <Table
                    columns={val.serviceTableColumns}
                    columnSizes={val.serviceTableSizes}
                    data={val.serviceTableData}
                    //onLoadMore={on.handleShowMore}
                    //totalData={get.totalData}
                    //paginate={get.paginate}
                    disabledIndexes={[val.serviceTableIdIndex, val.serviceTableIdIndex - 1]}
                    contentStyle={{background: '#fff'}}
                  />
                </div>
              </Lib.S.content>
            </div>
          </Lib.S.ModalContainer>
        </Modal>

        {/* add new overtime modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showAddNewModal}
          onCancel={() => set.setShowAddNewModal(false)}
          className="noHeaderModal userModal"
          width={700}
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>Ekle</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={() => set.setShowAddNewModal(false)} style={{ cursor: 'pointer' }} />
            </div>
            <div className="px-3">
              <div className="d-flex gap-2">
                <div className="d-flex gap-2 w-50">
                    <Input
                      type="search"
                      placeholder="Ara..."
                      lessBorder
                      value={val.searchValue.name}
                      onChange={value => {
                        set.setSearchValue({name: value.currentTarget.value, departmentId: undefined})
                        on.handleSearch(value)
                      }}
                      icon="search_alt"
                      iconProps={{ size: 14 }}
                      containerStyles={{height: 40}}
                      inputStyles={{height: 40}}
                    />
                  <DropDown2
                    className="dropdown"
                    options={val.departmentIds}
                    label=""
                    height="40px"
                    placeholder="Departman Seç"
                    value={val.searchValue.departmentId}
                    onChange={value => {
                      set.setSearchValue({name: '', departmentId: value})
                      on.handleSearchDepartment(value)
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between gap-2 w-50">
                  <Button
                    mood="accent"
                    callback={() => on.handleResetEmployeeFilter()}
                    text="Filtreyi Temizle"
                    iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
                    containerStyles={{ minHeight: 40, width: 130 }}
                    parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
                  />
                  <Button
                    mood="accent"
                    callback={() => on.handleAddEmployee()}
                    text="Onayla"
                    iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
                    containerStyles={{ minHeight: 40, width: 70 }}
                    parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
                  />
                </div>
              </div>
              <Lib.S.SelectAllContent>
                <div className="employee">
                  <Checkbox
                    label=""
                    color="accent"
                    checked={val.allSelected}
                    onChange={on.handleCheckboxChange}
                  />
                  <span>Tümünü Seç</span>
                </div>
              </Lib.S.SelectAllContent>
              <Lib.S.EmployeeContent>
                {val.isSearch ? get.searchResult?.map(employee => {
                  return (
                    <div className="employee">
                      <Checkbox
                        label=""
                        color="accent"
                        checked={employee.isSelected}
                        checkedColor={`${employee.disabled ? '#e5e5e5' : ''}`}
                        disabled={employee.disabled}
                        onChange={() => {
                          set.setSearchResult(perv => {
                            return perv?.map(pervSub => {
                              if (pervSub.id === employee.id) {
                                pervSub.isSelected = !pervSub.isSelected
                              }
                              return pervSub
                            })
                          })
                        }}
                      />
                      <span>{employee.name}</span>
                    </div>
                  )
                }) : get.employeeData?.map(employee => {
                  return (
                    <div className="employee">
                      <Checkbox
                        label=""
                        color="accent"
                        checked={employee.isSelected}
                        checkedColor={`${employee.disabled ? '#e5e5e5' : ''}`}
                        disabled={employee.disabled}
                        onChange={() => {
                          set.setEmployeeData(perv => {
                            return perv?.map(pervSub => {
                              if (pervSub.id === employee.id) {
                                pervSub.isSelected = !pervSub.isSelected
                              }
                              return pervSub
                            })
                          })
                        }}
                      />
                      <span>{employee.name}</span>
                    </div>
                  )
                })}
              </Lib.S.EmployeeContent>
            </div>
          </Lib.S.ModalContainer>
        </Modal>
        {/* Delete modal */}
        <Modal visible={val.showDeleteModal} onCancel={() => set.setShowDeleteModal(false)} footer={null} title="Çalışma Zamanını Sil">
          <Lib.S.DeleteModalContent>
            <p>silmek istediğinizden emin misiniz?</p>
            <div>
              <Button text="Vazgeç" mood="accent" callback={() => set.setShowDeleteModal(perv => !perv)} />
              <Button text="Sil" mood="danger" callback={() => on.handleDeleteOvertime()} />
            </div>
          </Lib.S.DeleteModalContent>
        </Modal>
        <Lib.S.FooterPage>
          <div className="page-footer">
            <div className="footer-container">
              <div>
                <span className="footer-info"></span> <span className="footer-value"></span>
              </div>

              <div>
                <span className="footer-info">Toplam : </span> <span className="footer-value">{get.totalData}</span>
              </div>
            </div>
          </div>
        </Lib.S.FooterPage>
      </Content>
    </PanelWrapper>
  )
}

export default Overtime
