import * as Lib from './lib'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'
import { BaseService } from './BaseService'

export class BaklaService extends BaseService{
  endpoint = 'baklas'
  subEndpoint = 'sub-baklas'

  constructor() {
    super();
  }

  // Bakla
  async create({ title, image }: Lib.T.Bakla.CreateArgs): Promise<Lib.T.Bakla.CreateResult> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: {
        title,
        image,
      },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 201 || !data) {
        return { success: false }
      }

      return { success: true, data: data }

  }

  async getList(args?: Lib.T.Bakla.GetListArgs): Promise<Lib.T.Bakla.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.baklas) {
        return { success: false }
      }

      return { success: true, data: data.baklas }

  }

  async delete(id: number, subBakla?: boolean): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(subBakla ? `${this.subEndpoint}/${id}` : `${this.endpoint}/${id}`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return false
      }

      return status === 200

  }

  async edit(id: number, args: Lib.T.Bakla.EditArgs): Promise<Lib.T.Bakla.EditResult> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data) {
        return { success: false }
      }

      return { success: true, data: data }

  }

  // sub Bakla

  // here
  async createSubBakla(args: Lib.T.Bakla.CreateSubBaklaArgs): Promise<Lib.T.Bakla.ResponseCreateSubBakla> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subEndpoint),
      headers: { authorization: this.accessToken },
      data: {
        ...args,
      },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response

      if (status !== 201 || !data.subBakla) {
        return { success: false, error: data }
      }

      return { success: true, data: data.subBakla, error: null }

  }

  async editSubBakla(id: number, args: Lib.T.Bakla.EditSubBaklaArgs): Promise<Lib.T.Bakla.EditSubBaklaResult> {
    const config: IApiCallerConfig = {
      method: 'PUT',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.subEndpoint}/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.subBakla) {
        return { success: false }
      }

      return { success: true, data: data.subBakla }

  }
  // here

  async getSubBakla(args?: Lib.T.Bakla.GetListArgs): Promise<Lib.T.Bakla.SubBaklaResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subEndpoint).concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.subBaklas) {
        return { success: false }
      }

      return { success: true, data: data.subBaklas, total: data.total }

  }

  // here
  async findOneSubBakla(id: number): Promise<Lib.T.Bakla.FindOneResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.subEndpoint}/${id}`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.subBakla) {
        return { success: false }
      }

      return {
        success: true,
        subBakla: data.subBakla,
      }

  }
}
