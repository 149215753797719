import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class UserService extends BaseService{
  endpoint = 'users'
  departmentEndpoint = 'department'
  roleEndpoint = 'role'

  constructor() {
    super();
  }

  async getList(filters?: Lib.T.User.GetListFilters): Promise<Lib.T.User.GetListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response

      if (status !== 200 || !data.users) {
        return { success: false, total: 0 }
      }
      return { success: true, users: data.users, total: data.total }
    
  }

  async getDepartmentList(isMain: string): Promise<Lib.T.User.GetDepartmentListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.departmentEndpoint).concat(isMain),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.departments) {
        return { success: false, total: 0, departments: [] }
      }
      return { success: true, departments: data.departments, total: data.total }
    
  }

  async getAgentList(): Promise<Lib.T.User.GetAgentListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/employee'),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data) {
        return { success: false, total: 0, agents: [] }
      }
      return { success: true, agents: data, total: data.total }
    
  }

  async getMainRoleList(): Promise<Lib.T.Role.GetListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.roleEndpoint).concat('?isMain=true'),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return { success: true, roles: data.roles, total: data.total }
      }
      return { success: false, total: 0, roles: [] }
    
  }

  async getSecondRoleList(): Promise<Lib.T.Role.GetListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.roleEndpoint).concat('?isMain=false'),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return { success: true, roles: data.roles, total: data.total }
      }
      return { success: false, total: 0, roles: [] }
    
  }

  async createOrEdit(args: Lib.T.User.CreateUserArgs, id?: number): Promise<Lib.T.User.CreateUserResult> {
    const config: IApiCallerConfig = {
      method: id ? 'PATCH' : 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id ? `/${id}` : ''),
      headers: { authorization: this.accessToken },
      data: args,
    }

    const response = this.callRefresh(config)
      const { data, status } = await response
      if (id) {
        if (status !== 200) {
          return { success: false }
        }
      } else {
        if (status !== 201) {
          return { success: false }
        }
      }

      return {
        success: true,
        user: data.user,
      }
    
  }

  async getOne(id: number): Promise<Lib.T.User.GetOneResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { data, status } = await response
      if (status !== 200 || !data.user) {
        return { success: false }
      }

      return { success: true, user: data.user }
    
  }

  async getDescription(id: number): Promise<Lib.T.User.GetDescriptionResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id + '/admin-description'),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { data, status } = await response
      if (status !== 200 || !data) {
        return { success: false }
      }

      return { success: true, description: data }
    
  }

  async putDescription(arg: { description: string }, id: number): Promise<Lib.T.User.GetDescriptionResult> {
    const config: IApiCallerConfig = {
      method: 'PUT',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id + '/admin-description'),
      headers: { authorization: this.accessToken },
      data: arg,
    }

    const response = this.callRefresh(config)
      const { status } = await response
      if (id) {
        if (status !== 200) {
          return { success: false }
        }
      } else {
        if (status !== 201) {
          return { success: false }
        }
      }

      return { success: true }
    
  }

  async getSellers(customerId: number): Promise<Lib.T.User.GetSellers> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/customer/' + customerId + '/findSellers'),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.sellers) {
        return { success: false, total: 0, sellers: [] }
      }

      return { success: true, total: data.total, sellers: data.sellers }
    
  }

  async getKarats(customerId: number): Promise<Lib.T.User.GetKarats> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/customer/' + customerId + '/findKarats'),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.karats) {
        return { success: false, total: 0, karats: [] }
      }

      return { success: true, total: data.total, karats: data.karats }
    
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200;
  }

  async findKaratsForMultipleCustomers(
    filters?: Lib.T.User.findKaratsForMultipleCustomersArgType,
  ): Promise<Lib.T.User.findKaratsForMultipleCustomersResponse> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('/customer/findKaratsForMultipleCustomers')
        .concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response

      if (status !== 200 || !data.karats) {
        return { success: false, total: 0 }
      }
      return { success: true, karats: data.karats, total: data.total }
    
  }
}
