import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'
import * as Lib from './lib'

export class TodoListService extends BaseService{
  endpoint = 'todo-list/'

  constructor() {
    super();
  }

  async getTodoList(args: Lib.T.TodoList.Args): Promise<Lib.T.TodoList.Result> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false, result: null, pendingResult: null }
      }
      return { success: true, result: data, pendingSuccess: true, pendingResult: data }
    
  }

  async delete(ids: number, date?: string): Promise<{success: boolean}> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: {ids:[ids], date}
    }

    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return { success: false }
      }
      return { success: true }
    
  }

  async addTask(args?: Lib.T.TodoList.AddTaskArgs): Promise<Lib.T.TodoList.TaskResponse> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args
    }

    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 201) {
        return { success: false }
      }
      return { success: true }
    
  }

  async editTask(args: Lib.T.TodoList.AddTaskArgs, id: number): Promise<Lib.T.TodoList.TaskResponse> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat(id.toString()),
      headers: { authorization: this.accessToken },
      data: args
    }

    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return { success: false }
      }
      return { success: true }
    
  }

  async shareTask(args: Lib.T.TodoList.AddTaskArgs): Promise<Lib.T.TodoList.TaskResponse> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('clone'),
      headers: { authorization: this.accessToken },
      data: args
    }
    const response = this.callRefresh(config)
    const { status } = await response
    if (status !== 201) {
      return { success: false }
    }
    return { success: true }
  }
}
