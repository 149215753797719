import { BaseService } from './BaseService'
import * as Lib from './lib'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class NewsAndAnnouncements extends BaseService {
  endpoint = 'automation/announcement/manage'

  constructor() {
    super();
  }

  async getAnnouncementsAndNews(filters?: Lib.T.NewsAndAnnouncements.GetListFilters): Promise<Lib.T.NewsAndAnnouncements.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.announcements) {
        return { success: false }
      }
      return { success: true, totalData: data.total, announcements: data.announcements, news: data.announcements }
    
  }

  async getOneAnnouncementAndNews(id: number): Promise<Lib.T.NewsAndAnnouncements.ListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id ? `/${id}` : ''),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data) {
        return { success: false }
      }
      return { success: true, newsItem: data, announcementsItem: data }
    
  }

  async putAnnouncementsAndNews(
    args: Lib.T.NewsAndAnnouncements.newsAndAnnouncementsArgs,
    id: number,
  ): Promise<Lib.T.NewsAndAnnouncements.ListResult> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id ? `/${id}` : ''),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      if (id) {
        if (status !== 200) {
          return { success: false }
        }
      } else {
        if (status !== 201) {
          return { success: false }
        }
      }

      return {
        success: true,
      }
    
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
    
  }

  async create(args: Lib.T.NewsAndAnnouncements.newsAndAnnouncementsArgs): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 201
    
  }
}
