import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'
import * as Lib from './lib'

export class ReportService extends BaseService{
  endpoint = 'reports/'

  constructor() {
    super();
  }

  async deliveredWeight(args: Lib.T.Order.WeightReportArgs): Promise<{ success: boolean; report: any | null }> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('deliveredWeight')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
      data: args,
    }

    const response = this.callRefresh(config)
      const { status, data } = await response

      if (status !== 200) {
        return { success: false, report: null }
      }
      return { success: true, report: data.report }
    
  }

  async customerWeight(args?: Lib.T.Report.CustomerWeightArgs): Promise<Lib.T.Report.CustomerWeightResponse> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('customerWeight')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false, report: null }
      }
      return { success: true, report: data.report }
    
  }

  async categoryWeight(args?: Lib.T.Report.CategoryWeightArgs): Promise<Lib.T.Report.CategoryWeightResponse> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('categoryWeight')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false, report: null }
      }
      return { success: true, report: data.report }
    
  }

  async areaWeight(args?: Lib.T.Report.AreaWeightArgs): Promise<Lib.T.Report.AreaWeightResponse> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('areaWeight')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false, report: null }
      }
      return { success: true, report: data.report }
    
  }

  async subCategoryWeight(args?: Lib.T.Report.SubCategoryWeightArgs): Promise<Lib.T.Report.SubCategoryWeightResponse> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('subCategoryWeight')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false, report: null }
      }
      return { success: true, report: data.report }
    
  }
}
