import { Content } from 'antd/lib/layout/layout'
import Modal from 'antd/lib/modal/Modal'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Icon } from 'common/components/ui-kit/icon'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from './lib'
import { useLeave } from './lib/hooks'
import React from 'react'
import { Sticky } from 'common/components/ui-kit/sticky'
import { DropDown2 } from 'common/components/ui-kit/dropdown2'
import { Table } from 'common/components/ui-kit/table-2'
import moment from 'moment/moment'
import locale from 'antd/es/date-picker/locale/tr_TR'
import { DatePicker, Switch, TimePicker } from 'antd'
import { Textarea } from 'common/components/ui-kit/textarea'

function LeaveList() {
  const { val, set, on, get } = useLeave()

  return (
    <PanelWrapper tab={['automation', '/automation/leave/list']}>
      <Sticky>
        <Lib.S.searchSection>
          <p className="title">İzin istekleri</p>
          <div>
            <div className="inputContainer">
              <Input
                type="search"
                placeholder="Ara..."
                lessBorder
                value={get.query.name}
                onInput={value => set.setQuery({ ...get.query, name: value.currentTarget.value })}
                onEnter={() => set.setFetchAgain(perv => !perv)}
              />
            </div>
            <Button
              className="buttonContainer"
              icon="search"
              mood="accent"
              callback={() => set.setFetchAgain(perv => !perv)}
              iconProps={{ color: 'white', size: 20 }}
            />
          </div>
          <div style={{ maxWidth: 400, gap: 10 }}>
            <DatePicker
              value={get.query.startFrom ? moment(get.query.startFrom, 'YYYY-MM-DD') : null}
              onChange={(date: moment.Moment | null) => {
                if (date) {
                  const formattedDateForServer = date.format('YYYY-MM-DD')
                  set.setQuery({ ...get.query, startFrom: formattedDateForServer })
                  set.setFetchAgain(prev => !prev)
                } else {
                  set.setQuery({ ...get.query })
                  set.setFetchAgain(prev => !prev)
                }
              }}
              size="small"
              locale={locale}
              allowClear={false}
              className="w-100"
              style={{ height: 40, borderRadius: 7 }}
              inputReadOnly
              format="DD-MM-YYYY"
            />
            <DatePicker
              value={get.query.endFrom ? moment(get.query.endFrom, 'YYYY-MM-DD') : null}
              onChange={(date: moment.Moment | null) => {
                if (date) {
                  const formattedDateForServer = date.format('YYYY-MM-DD')
                  set.setQuery({ ...get.query, endFrom: formattedDateForServer })
                  set.setFetchAgain(prev => !prev)
                } else {
                  set.setQuery({ ...get.query })
                  set.setFetchAgain(prev => !prev)
                }
              }}
              size="small"
              locale={locale}
              allowClear={false}
              className="w-100"
              style={{ height: 40, borderRadius: 7 }}
              inputReadOnly
              format="DD-MM-YYYY"
            />
          </div>
          <div style={{ maxWidth: 200 }}>
            <DropDown2
              className="dropdown"
              showSearch={false}
              options={get.departments}
              label=""
              height="40px"
              placeholder="Departman Seç"
              value={get.query.departmentId}
              onChange={value => {
                set.setQuery({ ...get.query, departmentId: value })
                set.setFetchAgain(perv => !perv)
              }}
            />
          </div>
          <div className="flex justify-content-end">
            <Button
              mood="accent"
              callback={() => {
                set.setQuery({ departmentId: undefined, startFrom: '', endFrom: '', name: '' })
                set.setFetchAgain(perv => !perv)
              }}
              text="Filtreyi Temizle"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              containerStyles={{ minHeight: 40, width: 135 }}
              parentStyles={{ maxWidth: 140 }}
            />
            <Button
              mood="accent"
              callback={() => {
                set.setModalProps({ ...val.modalInitialState, kind: 'create', title: '' })
                // set.setGroupId(0)
                on.handleCancelEditModal()
              }}
              text="Yeni İzin"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              containerStyles={{ minHeight: 40, width: 135 }}
              parentStyles={{ maxWidth: 140 }}
            />
          </div>
        </Lib.S.searchSection>
      </Sticky>
      <Content>
        <Lib.S.PageWrapper>
          <Lib.S.content>
            <div className="tableContainer">
              <Table
                columns={val.tableColumns}
                columnSizes={val.tableSizes}
                data={val.tableData}
                rowBackColor={false}
                disabledIndexes={[val.tableIdIndex, val.tableIdIndex - 1]}
                maxHeight="calc(100vh - 110px)"
              />
            </div>
          </Lib.S.content>
        </Lib.S.PageWrapper>

        {/* edit modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showEditModal}
          onCancel={on.handleCancelEditModal}
          className="noHeaderModal userModal"
          width={700}
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>Yeni istek</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={on.handleCancelEditModal} style={{ cursor: 'pointer' }} />
            </div>
            <div className="row px-3 mt-3">
              <div className="col-lg-6 gap-2">
                <span className="fw-bold" style={val.leaveIsEmpty && !val.employeeId ? { color: 'red' } : undefined}>
                  Ad <span style={{ color: 'red' }}>*</span>
                </span>
                <DropDown2
                  className="dropdown"
                  options={get.employees}
                  label=""
                  height="40px"
                  value={val.employeeId}
                  onChange={value => set.setEmployeeId(value)}
                  haveError={val.leaveIsEmpty && !val.employeeId}
                />
              </div>
              <div className="col-lg-6 gap-2">
                <span className="fw-bold" style={val.leaveIsEmpty && !val.typeLeaveSelected ? { color: 'red' } : undefined}>
                  İzinTipi <span style={{ color: 'red' }}>*</span>
                </span>
                <DropDown2
                  className="dropdown"
                  options={get.typeLeave}
                  label=""
                  height="40px"
                  value={val.typeLeaveSelected}
                  onChange={value => {
                    set.setTypeLeaveSelected(value)
                    get.typeLeave.map(item => {
                      value === item.id && set.setIsHourly(item.isHourly)
                    })
                  }}
                  haveError={val.leaveIsEmpty && !val.typeLeaveSelected}
                />
              </div>
            </div>
            {val.isHourly ? (
              <div className="row mt-3 px-3">
                <div className="col-lg-4">
                  <div className="antDatePicker w-100 d-flex flex-column">
                    <span className="fw-bold" style={val.leaveIsEmpty && !Boolean(val.date) ? { color: 'red' } : undefined}>
                      Tarihi <span style={{ color: 'red' }}>*</span>
                    </span>
                    <DatePicker
                      value={val.date ? moment(val.date, 'YYYY-MM-DD') : null}
                      onChange={(date: moment.Moment | null) => {
                        if (date) {
                          const formattedDateForServer = date.format('YYYY-MM-DD')
                          set.setDate(formattedDateForServer)
                        } else {
                          set.setDate('')
                        }
                      }}
                      size="small"
                      locale={locale}
                      allowClear={false}
                      style={{
                        height: 46,
                        borderRadius: 7,
                        borderColor: val.leaveIsEmpty && !Boolean(val.date) ? 'red' : 'rgb(0 0 0 / 10%)',
                        backgroundColor: val.leaveIsEmpty && !Boolean(val.date) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                      }}
                      inputReadOnly
                      format="DD-MM-YYYY"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="antDatePicker w-100 d-flex flex-column">
                    <span className="fw-bold" style={val.leaveIsEmpty && !Boolean(val.startHour) ? { color: 'red' } : undefined}>
                      İtibaren <span style={{ color: 'red' }}>*</span>
                    </span>
                    <TimePicker
                      onChange={(_d: any, val: any) => set.setStartHour(moment(_d))}
                      value={val.startHour ? moment(val.startHour) : undefined}
                      size="small"
                      locale={locale}
                      format={'HH:mm'}
                      style={{
                        height: 46,
                        borderRadius: 7,
                        borderColor: val.leaveIsEmpty && !Boolean(val.startHour) ? 'red' : 'rgb(0 0 0 / 10%)',
                        backgroundColor: val.leaveIsEmpty && !Boolean(val.startHour) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="antDatePicker w-100 d-flex flex-column">
                    <span className="fw-bold" style={val.leaveIsEmpty && !Boolean(val.endHour) ? { color: 'red' } : undefined}>
                      ile <span style={{ color: 'red' }}>*</span>
                    </span>
                    <TimePicker
                      onChange={(_d: any, val: any) => set.setEndHour(moment(_d))}
                      value={val.endHour ? moment(val.endHour) : undefined}
                      size="small"
                      locale={locale}
                      format={'HH:mm'}
                      style={{
                        height: 46,
                        borderRadius: 7,
                        borderColor: val.leaveIsEmpty && !Boolean(val.endHour) ? 'red' : 'rgb(0 0 0 / 10%)',
                        backgroundColor: val.leaveIsEmpty && !Boolean(val.endHour) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="row mt-3 px-3">
                <div className="col-lg-4">
                  <div className="antDatePicker w-100 d-flex flex-column">
                    <span className="fw-bold" style={val.leaveIsEmpty && !Boolean(val.startDate) ? { color: 'red' } : undefined}>
                      Başlangıç tarihi <span style={{ color: 'red' }}>*</span>
                    </span>
                    <DatePicker
                      value={val.startDate ? moment(val.startDate, 'YYYY-MM-DD') : null}
                      onChange={(date: moment.Moment | null) => {
                        if (date) {
                          const formattedDateForServer = date.format('YYYY-MM-DD')
                          set.setStartDate(formattedDateForServer)
                        } else {
                          set.setStartDate('')
                        }
                      }}
                      size="small"
                      locale={locale}
                      allowClear={false}
                      style={{
                        height: 46,
                        borderRadius: 7,
                        borderColor: val.leaveIsEmpty && !Boolean(val.startDate) ? 'red' : 'rgb(0 0 0 / 10%)',
                        backgroundColor: val.leaveIsEmpty && !Boolean(val.startDate) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                      }}
                      inputReadOnly
                      format="DD-MM-YYYY"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="antDatePicker w-100 d-flex flex-column">
                    <span className="fw-bold" style={val.leaveIsEmpty && !Boolean(val.endDate) ? { color: 'red' } : undefined}>
                      Bitiş tarihi <span style={{ color: 'red' }}>*</span>
                    </span>
                    <DatePicker
                      value={val.endDate ? moment(val.endDate, 'YYYY-MM-DD') : null}
                      onChange={(date: moment.Moment | null) => {
                        if (date) {
                          const formattedDateForServer = date.format('YYYY-MM-DD')
                          set.setEndDate(formattedDateForServer)
                        } else {
                          set.setEndDate('')
                        }
                      }}
                      size="small"
                      locale={locale}
                      allowClear={false}
                      style={{
                        height: 46,
                        borderRadius: 7,
                        borderColor: val.leaveIsEmpty && !Boolean(val.endDate) ? 'red' : 'rgb(0 0 0 / 10%)',
                        backgroundColor: val.leaveIsEmpty && !Boolean(val.endDate) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                      }}
                      inputReadOnly
                      format="DD-MM-YYYY"
                    />
                  </div>
                </div>
                {!val.isHourly && (
                  <div className="col-lg-4">
                    <Input
                      label="gün"
                      type="number"
                      onChange={event => {
                        set.setDayCount(parseInt(event.target.value))
                      }}
                      labelStyles={{
                        fontWeight: 'bold',
                        padding: 0,
                        color: val.leaveIsEmpty && !Boolean(val.dayCount) ? 'red' : 'unset',
                      }}
                      inputStyles={{
                        height: 42,
                        borderColor: val.leaveIsEmpty && !Boolean(val.dayCount) ? 'red' : 'rgb(0 0 0 / 10%)',
                        backgroundColor: val.leaveIsEmpty && !Boolean(val.dayCount) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                      }}
                      lessBorder
                      required
                      value={val.dayCount}
                      onFocus={event =>
                        event.target.addEventListener(
                          'wheel',
                          function (event) {
                            event.preventDefault()
                          },
                          { passive: false },
                        )
                      }
                    />
                  </div>
                )}
              </div>
            )}
            <div className="row px-3 mt-2">
              <Textarea
                label="Açıklama"
                lessBorder
                labelStyles={{ fontWeight: 'bold' }}
                rows={1}
                className="description"
                value={val.description}
                onInput={evt => set.setDescription(evt.currentTarget.value)}
              />
            </div>
            <div className="btn">
              <Button
                text="Vazgeç"
                mood="accent_outline"
                callback={() => {
                  on.handleCancelEditModal()
                }}
                className="btn"
              />
              <Button
                text={val.modalProps.kind === 'edit' ? 'Düzenle' : 'Oluştur'}
                mood="accent"
                callback={() => on.EditOrCreateUser()}
                className="btn"
              />
            </div>
          </Lib.S.ModalContainer>
        </Modal>
      </Content>
      {/* Delete modal */}
      <Modal visible={val.showDeleteModal} onCancel={on.handleDeleteModalVisibility} footer={null} title="İzin Silme">
        <Lib.S.DeleteModalContent>
          <p>İzin talebinizi silmek istiyor musunuz?</p>
          <Input
            label="Lütfen silme nedenini yazınız"
            onChange={event => {
              set.setDeleteReason(event.target.value)
            }}
            labelStyles={{ fontWeight: 'bold', padding: 0 }}
            inputStyles={{ height: 42 }}
            lessBorder
            value={val.deleteReason}
          />
          <div>
            <Button text="Sil" mood="danger" callback={() => on.DeleteLeave(val.modalProps.editProps.id)} />
            <Button text="Vazgeç" mood="accent" callback={() => set.setShowDeleteModal(perv => !perv)} />
          </div>
        </Lib.S.DeleteModalContent>
      </Modal>
    </PanelWrapper>
  )
}

export default LeaveList
