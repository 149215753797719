import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'

export class PrinterService extends BaseService{

  constructor() {
    super();
  }

  async create(args: Lib.T.Printer.MethodsArgs): Promise<Lib.T.Printer.CreateOrEditReturnType> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat('printer'),
      headers: { authorization: this.accessToken },
      data:{...args}
    }

    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 201) {
        return { success: false }
      }

      return {
        success: true,
      }
    
  }

  async edit(id: number, args: Lib.T.Printer.MethodsArgs): Promise<Lib.T.Printer.CreateOrEditReturnType> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat('printer/').concat(id.toString()),
      headers: { authorization: this.accessToken },
      data:{...args}
    }

    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return { success: false }
      }

      return { success: true }
    
  }

  async toggleDisabled(id: number): Promise<Lib.T.Printer.CreateOrEditReturnType> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat('printer/').concat(id.toString())!.concat('/toggle-disabled'),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false, message: data.message }
      }

      return { success: true }
    
  }

  async readSingle(id: number): Promise<Lib.T.Printer.CreateOrEditReturnType> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat('printer/').concat(id.toString()),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data) {
        return { success: false }
      }

      return { success: true, data: data }
    
  }


  async read(args?: Lib.T.Printer.ReadArgs): Promise<Lib.T.Printer.ReadReturnType> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(
        `printer?page=${args?.page || 1}&limit=${args?.limit || 99999999}`,
      ),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false, total: 0 }
      }
      return {
        success: true,
        data: data.printers,
        total: data.total,
      }
    
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`printer/${id}`),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || data !== '') {
        return false
      }

      return true
    
  }
}