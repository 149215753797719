import { Crypto } from 'common/helpers/crypto.helper'
import { DOM } from 'common/helpers/dom'
import { Storage } from 'common/helpers/storage'
import { useLoader } from 'common/hooks/use-loader'
import { AuthenticationService } from 'common/services'
import { SavedUser } from 'common/typing/saved-user.interface'
import { useState } from 'react'
import { useHistory } from 'react-router'
import swal from 'sweetalert'
import * as Lib from '.'
import { getFirebaseToken } from '../../../../firebase'
import { routes } from 'common/routes/routes'
import { SecondaryPermissions } from 'common/routes/types/roles.type'

export const useLogin = () => {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const history = useHistory()
  const { setLoader } = useLoader()

  const onSubmit = async () => {
    try {
      const token = await getFirebaseToken()

      const authService = new AuthenticationService(username, password, token!)
      setLoader(true)
      const result = await authService.signIn()
      setLoader(false)

      if (result) {
        if (result.status === 201) {
          const data = <Lib.T.SignedInUserData>result.data
          const { OTPToken } = data

          if (!OTPToken) {
            const user: SavedUser = {
              accessToken: data.access_token,
              refreshToken: data.refresh_token,
              group: data.user.group,
              permissions: data.user.role,
              userType: data.user.userType,
              username: data.user.username,
              phone: <string>Crypto.encrypt(data.user.phone),
              id: data.user.id,
              time: new Date().toISOString(),
              employee: data.user.employee,
              avatar: data.user?.avatar
            }
            Storage.set<SavedUser>('user', user)

            window.location.href = '/dashboard'
          } else {
            const verifyData = <Lib.T.NonManagerLoginData>(<unknown>data)
            const { code, message, OTPToken } = verifyData
            //const encryptedCode = Crypto.encrypt(code.toString())
            const encryptedPhone = Crypto.encrypt(message)
            const encryptedPassword = Crypto.encrypt(password)
            const encryptedEmail = Crypto.encrypt(username)
            const encryptedOTPToken = OTPToken

            if (!encryptedPhone || !encryptedPassword || !encryptedEmail || !encryptedOTPToken) {
              swal({
                text: 'hello',
                icon: 'error',
                dangerMode: true,
              })
              return
            }
            history.push({
              pathname: '/authenticate/verification',
              search: DOM.objectToSearch({
                c: code ? code.toString() : undefined, // code
                m: Crypto.encryptedToUrl(encryptedPhone), // mobile: phone
                p: Crypto.encryptedToUrl(encryptedPassword), // password
                e: Crypto.encryptedToUrl(encryptedEmail), // email
                otp: encryptedOTPToken,
              }),
            })
          }
        }
      }
    } catch (e) {}
  }

  return {
    email: {
      value: username,
      setValue: setUsername,
    },
    password: {
      value: password,
      setValue: setPassword,
    },
    onSubmit,
  }
}
