import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import * as Lib from './lib'
import moment from 'moment'
import { Input } from 'common/components/ui-kit/input'
import { Checkbox } from 'common/components/ui-kit/checkbox'
import { HeaderTabEnum } from './lib/types'
import React from 'react'
import { Icon } from 'common/components/ui-kit/icon'
import { DatePicker, Modal, Popover, Spin } from 'antd'
import { handleSetDate } from 'common/store/actions/global.action'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../common/components/ui-kit/button'
import locale from 'antd/es/date-picker/locale/tr_TR'
import { DropDown2 } from '../../../common/components/ui-kit/dropdown2'
import { RootState } from '../../../common/store/root-reducer'

const TodoList = () => {
  const { user } = useSelector((state: RootState) => state.globals)
  const dispatch = useDispatch()
  const { header, modal, get, on, set } = Lib.H.useTodoList()

  moment.updateLocale('tr', {
    weekdaysShort: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
  })

  const emptyDay = () => {
    const contentArray = []
    const monthIndex = get.month && moment().locale('en').month(get.month.date).month()
    let day = moment({ year: get.year ? parseInt(get.year?.date) : 2024, month: monthIndex })
      .startOf('month')
      .format('d')
    for (let i = 0; i < parseInt(day) - 1; i++) {
      contentArray.push(
        <div className="monthCard">
          <span></span>
        </div>,
      )
    }

    return contentArray
  }

  const renderHeaderTabs = () => {
    switch (header.headerActiveTab) {
      case HeaderTabEnum.YEAR:
        return (
          <Lib.S.notesContainer>
            <div className="body">
              <h4 className="fw-bold mb-3" style={{ textAlign: 'center', marginTop: '-42px' }}>
                {get.year?.date}
              </h4>
              <div className="d-flex flex-wrap">
                {get.year?.result?.map((item, index) => {
                  return (
                    <div
                      className="card col-3"
                      key={item.monthName}
                      onClick={() => {
                        const monthIndex = moment().locale('en').month(item.monthName).month()
                        const date = moment({ year: get.year ? parseInt(get.year?.date) : 2024, month: monthIndex })
                        const formattedDate = date.format('YYYY-MM-DDTHH:mm:ss.SSS')
                        dispatch(handleSetDate(formattedDate))
                        header.handleChangeHeaderActiveTab(HeaderTabEnum.MONTH, formattedDate)
                      }}
                    >
                      <h5>{on.convertDayName(item.monthName)}</h5>
                      {item.count ? <div className="task">{item.count} Task</div> : ''}
                    </div>
                  )
                })}
              </div>
            </div>
          </Lib.S.notesContainer>
        )
      case HeaderTabEnum.MONTH:
        return (
          <Lib.S.notesContainer>
            <div className="body">
              <h4 className="fw-bold mb-3" style={{ textAlign: 'center', marginTop: '-42px' }}>
                {on.convertDayName(get.month.date)} {get.year?.date}
              </h4>
              <div className="monthCardHeader">
                <div>Pzt</div>
                <div>Sal</div>
                <div>Çar</div>
                <div>Per</div>
                <div>Cum</div>
                <div>Cmt</div>
                <div>Paz</div>
              </div>
              <div className="d-flex flex-wrap">
                {emptyDay()}
                {get.month?.result?.map((item, index) => {
                  const todayNumber = moment().date()
                  const currentMonth = moment().month()
                  const incomeMonth = get.month && moment().locale('en').month(get.month?.date).month()
                  return (
                    <div
                      className="monthCard"
                      onClick={() => {
                        const monthIndex = get.month && moment().locale('en').month(get.month?.date).month()
                        const date = monthIndex
                          ? moment({ year: get.year ? parseInt(get.year?.date) : 2024, month: monthIndex })
                            .date(item.day)
                            .format('YYYY-MM-DDTHH:mm:ss.SSS')
                          : moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
                        dispatch(handleSetDate(date))
                        header.handleChangeHeaderActiveTab(HeaderTabEnum.WEEK, '', date)
                      }}
                    >
                      <span>{item.day}</span>
                      {item.count ? <div className={`${item.unCheckedCount > 0 && item.day < todayNumber && currentMonth >= incomeMonth ? 'uncheckTask' : 'task' }`}>{item.count} Task</div> : ''}
                    </div>
                  )
                })}
              </div>
            </div>
          </Lib.S.notesContainer>
        )
      case HeaderTabEnum.WEEK:
        return (
          <Lib.S.notesContainer>
            <div className="body">
              <h4 className="fw-bold mb-3" style={{ textAlign: 'center', marginTop: '-42px' }}>
                {on.convertDayName(get.month.date)} {get.year?.date}
              </h4>
              <div className="d-flex flex-wrap justify-content-between">
                <div style={{width: '70%'}} className="d-flex flex-wrap justify-content-between">
                  {get.week?.result?.map((item, index) => {
                    const date = item.dayName.date.split(' - ')
                    return (
                      <div className="weekCard">
                        <div className="title">
                        <span>
                          {date.map((title, index) => {
                            return index === 1
                              ? ' - ' + moment(title).utcOffset(0).format('DD MMM ddd')
                              : moment(title).utcOffset(0).format('DD MMM ddd')
                          })}
                        </span>
                          {!get.isShowDescription.state && (
                            <Icon
                              onClick={() => {
                                set.setIsShowDescription({ state: !get.isShowDescription.state, id: index, date: date[0], mode: 'add' })
                                set.setDescription('')
                              }}
                              name="plus_with_border"
                              size={20}
                              color="#fff"
                            />
                          )}
                          {get.isShowDescription.state && get.isShowDescription.id === index && (
                            <Icon
                              onClick={() => set.setIsShowDescription({ state: !get.isShowDescription.state, id: index, date: '', mode: '' })}
                              name="cross_with_border"
                              size={20}
                              color="#fff"
                            />
                          )}
                        </div>
                        <div className="d-flex flex-column justify-content-between" style={{ height: 220 }}>
                          <div className="notesContainer mt-2 mb-2 pe-3 ps-1">
                            {item.todos?.map(taskItem => {
                              const assignTask = taskItem?.assignedList?.some((item) => {
                                return user.username === item.username
                              })
                              const assignedList = taskItem?.assignedList?.map((item) => {
                                return item.username
                              })
                              const checkedTask = taskItem?.assignedList?.some((item) => {
                                return item.isChecked === true
                              })
                              const assignByTask = taskItem?.assignedBy?.username === user.username ?? false
                              return (
                                <div className="d-flex justify-content-between mt-2">
                                  <div className="d-flex checkboxTask">
                                    <Checkbox
                                      label=""
                                      name=""
                                      onChange={event => {
                                        set.setIsRead({ state: event.target.checked, id: taskItem.id })
                                        set.setOpenActions({ ...get.openActions, id: taskItem.id, mode:'' })
                                      }}
                                      checked={taskItem.isChecked}
                                      style={{ width: 'auto', marginRight: 5, height: 'auto' }}
                                    />
                                    <div className="d-flex flex-column justify-content-between">
                                      <span className={`${taskItem.isChecked ? 'checked' : ''}`}>{taskItem.description}</span>
                                      {!assignTask && taskItem?.assignedList?.length > 0 && <span className='blue'>Gönderen: {assignedList.map((item, index) => (assignedList?.length - 1 === index ? item : item + ' , '))}</span>}
                                      {!assignTask && taskItem?.assignedList?.length > 0 && checkedTask && <span className='green'>Tamamlayan: {taskItem?.assignedList.map((item) => item.isChecked === true ? item.username : '')}</span>}
                                    </div>
                                  </div>
                                  {!assignByTask && <Popover
                                    visible={get.openActions.state && taskItem.id === get.openActions.id}
                                    onVisibleChange={() => {
                                      set.setOpenActions({ state: !get.openActions.state, id: taskItem.id, mode:'' })
                                      set.setIsShowDescription({ state: false, date: '', id: index, mode: '' })
                                    }}
                                    content={get.actionTask(taskItem)}
                                    trigger="click"
                                    placement="left"
                                  >
                                    <Icon name="menu_dots" size={18} color="#004E42" />
                                  </Popover>}
                                  {get.spinLoader && taskItem.id === get.openActions.id && <Spin className="mt-3 spinLoader" />}
                                </div>
                              )
                            })}
                          </div>
                          {get.isShowDescription.state && get.isShowDescription.id === index && (
                            <div className="sendNoteContainer">
                              <Input
                                className="w-100"
                                autoFocus
                                defaultValue={get.description}
                                onEnter={() => on.handleAddTask()}
                                onChange={event => set.setDescription(event.target.value)}
                                type="text"
                                placeholder="Metni girin..."
                              />
                              <Icon onClick={() => on.handleAddTask()} name="send" size={18} color="#004E42" />
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div style={{width: '29.3%'}} className="d-flex flex-wrap justify-content-between">
                  <div className="weekPendingCard">
                    <div className="title">
                        <span>
                          Taslak Notlar
                        </span>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <div className="sendNoteContainer">
                        <Input
                          className="w-100"
                          autoFocus
                          defaultValue={get.pendingDescription}
                          value={get.pendingDescription}
                          onEnter={ () => get.pendingTaskMode === 'add' ? on.handleAddPendingTask() : get.pendingTaskMode === 'edit' && on.handleEditPendingTask()}
                          onChange={event => set.setPendingDescription(event.target.value)}
                          type="text"
                          placeholder={get.pendingTaskMode === 'add' ? 'Metni girin...' : ''}
                        />
                        <div className='d-flex align-items-center justify-content-between'>
                          {get.pendingTaskMode === 'edit' && <Icon onClick={ () => on.clearTextBox()} name="cross_with_border" style={{marginRight: 5}} size={18} color="red" />}
                          <Icon onClick={ () => get.pendingTaskMode === 'add' ? on.handleAddPendingTask() : get.pendingTaskMode === 'edit' && on.handleEditPendingTask()} name="send" size={18} color="#004E42" />
                        </div>
                      </div>
                      <div className="notesContainer mt-2 mb-2 pe-3 ps-1">
                        {get.pendingTask?.result?.map((taskItem, index) => {
                          return (
                            <div className="d-flex justify-content-between mt-2">
                              <div className="d-flex checkboxTask">
                                <span>{taskItem.description}</span>
                              </div>
                              <Popover
                                visible={get.openActions.state && taskItem.id === get.openActions.id}
                                onVisibleChange={() => {
                                  set.setOpenActions({ state: !get.openActions.state, id: taskItem.id ?? 0, mode:'pending' })
                                }}
                                content={get.actionPendingTask(taskItem)}
                                trigger="click"
                                placement="left"
                              >
                                <Icon name="menu_dots" size={18} color="#004E42" />
                              </Popover>

                            </div>
                          )
                        })}
                      </div>

                    </div>
                    {get.spinLoaderPendingTsk && <Spin className="mt-3 spinLoader m-auto w-100" />}
                  </div>
                </div>
              </div>
            </div>
          </Lib.S.notesContainer>
        )

      default:
        return null
    }
  }

  return (
    <PanelWrapper tab={['todoList', '/todoList']}>
      <div className="container">
        <Lib.S.Wrapper>
          <div className="d-flex justify-content-between align-items-center">
            <div className="tabs">
              {header.headerTabs.map((tab, index) => (
                <>
                  <div
                    className={`tab ${tab === header.headerActiveTab ? 'active' : undefined}`}
                    onClick={() => header.handleChangeHeaderActiveTab(tab)}
                  >
                    {tab}
                  </div>
                </>
              ))}
            </div>
            <div className="navContainer d-flex justify-content-between align-items-center">
              <div className="nav d-flex justify-content-center align-items-center" onClick={() => on.handelPrev()}>
                <Icon name="arrow_2_left" size={12} color="#313131" />
                <span className="ms-2">Önce</span>
              </div>
              <div className="nav d-flex justify-content-center align-items-center" onClick={() => on.handelNext()}>
                <span className="me-2">Sonra</span>
                <Icon name="arrow_2_right" size={12} color="#313131" />
              </div>
            </div>
          </div>
          {renderHeaderTabs()}
        </Lib.S.Wrapper>
      </div>
      <Modal {...modal.moveModalProps}>
        <Lib.S.modalContainer>
          <h6 className="fw-bold text-center">Başka Güne Taşı</h6>
          <p className="text-center">“Lorem ipsum dolor sit amet consectetur.”</p>
          <div className="d-flex justify-content-between align-items-center mt-5">
            {get.week?.result?.map((item, index) => {
              const date = item.dayName.date.split(' - ')
              return (
                <div
                  className={`${get.daySelected.state && get.daySelected.id === index ? 'cardSelected' : 'card'}`}
                  onClick={() => set.setDaySelected({ state: true, id: index, date: date[0] })}
                >
                  {date.map((title, index) => {
                    return index === 1 ? ' - ' + moment(title).format('dddd DD MMM') : moment(title).format('dddd DD MMM')
                  })}
                </div>
              )
            })}
            <div
              className={`${get.daySelected.state && get.daySelected.id === 6 ? 'cardSelected flex-column' : 'card flex-column'}`}
              style={{ width: 130 }}
            >
              <span>Diğer tarih</span>
              <DatePicker
                value={get.daySelected.id === 6 ? moment(get.daySelected.date, 'YYYY-MM-DD') : null}
                onChange={(date: moment.Moment | null) => {
                  if (date) {
                    const formattedDateForServer = date.format('YYYY-MM-DD')
                    set.setDaySelected({ state: true, id: 6, date: formattedDateForServer })
                  } else {
                    set.setDaySelected({ state: false, id: 0, date: '' })
                  }
                }}
                size="small"
                locale={locale}
                allowClear={false}
                className="w-100"
                style={{ height: 42, width: '65%', borderRadius: 7 }}
                inputReadOnly
                format="DD-MM-YYYY"
              />
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-5">
            <Button parentStyles={{ width: 155, marginRight: 10 }} text="Gönder" mood="accent" callback={() => on.handleMoveTask()} />
            <Button
              parentStyles={{ width: 155 }}
              containerStyles={{ border: '1px solid' }}
              text="Vazgeç"
              mood="accent_outline"
              callback={() => {
                set.setDaySelected({ state: false, date: '', id: 0 })
                set.setMoveModal(false)
              }}
            />
          </div>
        </Lib.S.modalContainer>
      </Modal>
      <Modal {...modal.shareModalProps}>
        <Lib.S.modalContainer>
          <h6 className="fw-bold text-center">Paylaş</h6>
          <div className="d-flex flex-column">
            <span>Personel</span>
            <DropDown2
              lessBorder
              loading={get.userLoading}
              disabled={get.userLoading}
              size="middle"
              className="customers-multiple"
              removeIcon={false}
              mode="multiple"
              height="42px"
              value={get.userIds}
              onChange={on.onUserDropDownChange}
              maxTagTextLength={7}
              options={get.users}
              filterOption={(input, option) => {
                return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-5">
            <Button
              parentStyles={{ width: 155,  marginRight: 10 }}
              containerStyles={{ border: '1px solid' }}
              text="Vazgeç"
              mood="accent_outline"
              callback={() => {
                set.setShareModal(false)
                set.setUserIds([])
              }}
            />
            <Button parentStyles={{ width: 155 }} text="Gönder" mood="accent" callback={() => on.handleShareTask()} />
          </div>
        </Lib.S.modalContainer>
      </Modal>
    </PanelWrapper>
  )
}
export default TodoList
