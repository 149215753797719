import { appName } from 'common/constants/strings'
import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Route } from 'react-router-dom'
import { useNewPermissions } from './hooks'
import { routes } from './routes'
import { useHistory } from 'react-router'

export const Private: React.FC = (): JSX.Element => {
  const history = useHistory()
  const {permission, secondaryPermission} = useNewPermissions()
  let url = window.location.pathname
  return (
    <>
      {Object.keys(routes.private).map((key, index) => {
        const { return: page, get: route, title, permissions, newPermissions, secondaryPermissions } = routes.private[key]
        if (url === '/authenticate/login') {
          history.push('/dashboard')
          return
        }
        return newPermissions?.some(role=> permission?.includes(role)) ? (
          <React.Fragment key={index}>
            <Route path={route} exact={route === '/'}>
              <Helmet>
                <title>
                  {title} | {appName}
                </title>
              </Helmet>
              {page}
            </Route>
          </React.Fragment>
        ) : (
          secondaryPermissions?.some(role=> secondaryPermission?.includes(role)) ? (
              <React.Fragment key={index}>
                <Route path={route} exact={route === '/'}>
                  <Helmet>
                    <title>
                      {title} | {appName}
                    </title>
                  </Helmet>
                  {page}
                </Route>
              </React.Fragment>
            ) :
            <Fragment key={index} />
        )
      })}
    </>
  )
}
