import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { Chain } from './lib/types/chains'

export class SubPiecesService extends BaseService{
  subPiece!: Lib.T.SubPieces.SubPieces

  constructor(subPiece: Lib.T.SubPieces.SubPieces) {
    super();
    this.subPiece = subPiece
  }

  async edit(id: number, args: Lib.T.SubPieces.EditArgs): Promise<{ success: boolean; data: Chain | null }> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece).concat('/' + id),
      headers: { authorization: this.accessToken },
      data: args,
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return { success: true, data: data.chain }
      }
      return { success: false, data: null }
    
  }

  async create<T>(args: Lib.T.SubPieces.CreateMine) {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece),
      headers: { authorization: this.accessToken },
      data: { ...args },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 201) {
        return { success: false, data: null }
      }

      const mineData = <T>data

      return { success: true, data:mineData }
    
  }

  async read<T>(filters?: string): Promise<Lib.T.SubPieces.ReadReturnType<T>> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece).concat('?' + filters ?? ''),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false }
      }
      const subPieceData = <T>data
      return { success: true, data:subPieceData }
    
  }

  async createStone(
    stoneType: number,
    stoneShape: number,
    stoneSize: number,
    stoneColor: number,
    image: string,
    weight: number,
    price: number,
  ): Promise<Lib.T.SubPieces.CreateStoneReturn> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece),
      headers: { authorization: this.accessToken },
      data: {
        stoneColor,
        stoneShape,
        stoneType,
        stoneSize,
        image,
        weight,
        price,
      },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      const stoneData: Lib.T.SubPieces.CreateStoneDate = data
      if (status !== 201) {
        return { success: false }
      }

      return {
        success: true,
        stone: stoneData.stone,
      }
    
  }

  async readStonesByFilter<T>(filter: Lib.T.SubPieces.GetStonesFilter) {
    let queryString = ''
    const filterKeys = Object.keys(filter)
    const filterValues = Object.values(filter)
    filterKeys.forEach((item, index) => {
      if (item && filterValues[index]) {
        queryString += `${item}=${filterValues[index]}&`
      }
    })
    return this.read<T>(queryString)
  }

  async readOneStone(id: number): Promise<Lib.T.SubPieces.ReadOneStoneResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece).concat('/' + id),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200 || !data.stone) {
        return { success: false }
      }
      return {
        success: true,
        stone: data.stone,
      }
    
  }

  async createChain(chainData: Lib.T.SubPieces.CreateChainArgs): Promise<Lib.T.SubPieces.CreateAndReadChainResult> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece),
      headers: { authorization: this.accessToken },
      data: chainData,
    }
    const response = this.callRefresh(config)
      const { status, data } = await response

      if (status !== 201 || !data) {
        return { success: false }
      }
      const { chain } = <Omit<Lib.T.SubPieces.CreateAndReadChainResult, 'success'>>data

      return { success: true, chain }
    
  }

  async getChainDetails(id: number): Promise<Lib.T.SubPieces.CreateAndReadChainResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece + '/').concat(id.toString()),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (!status || !data) {
        return { success: false }
      }

      return { success: true, chain: data }
    
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece + '/').concat(id.toString()),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status } = await response

      return status === 200;
  }
}
