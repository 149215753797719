import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'

export class CustomerService extends BaseService{
  endpoint = 'users/customer'
  listEndpoint = 'users/customer/customerList/'
  sliderEndpoint = 'slide'

  constructor() {
    super();
  }

  async getList(args?: Lib.T.Customer.GetListArgs): Promise<Lib.T.Customer.GetListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(DOM.objectToSearch(args ?? {})),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return { success: true, customers: data.customers, total: data.total }
      }
      return { success: false, total: 0, customers: [] }
    
  }

  async getListCustomerOrderList(args?: Lib.T.Customer.GetListArgs): Promise<Lib.T.Customer.GetListResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.listEndpoint).concat(DOM.objectToSearch(args ?? {})),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return { success: true, customers: data, total: data.total }
      }
      return { success: false, total: 0, customers: [] }
    
  }

  async create(args: Lib.T.Customer.CreateArgs): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    }

    const response = this.callRefresh(config)
      const { status } = await response
      return status === 201
    
  }

  async edit(args: Lib.T.Customer.EditArgs, id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    }

    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
    
  }

  async approve(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`).concat('/approve'),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
    
  }

  async delete(id: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
    }
    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
    
  }

  async sliderImage(
    data: Lib.T.Customer.SliderImage,
  ): Promise<{ success: boolean }> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.sliderEndpoint),
      headers: { authorization: this.accessToken },
      data,
    }

    const response = this.callRefresh(config)
      const { status } = await response

      if (status !== 201) {
        return { success: false }
      }

      return { success: true }
    
  }

  async getSliderImages(): Promise<Lib.T.Customer.GetSliderImagesResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat('sliders'),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return { success: true, images: data?.sliders }
      } else {
        return { success: false }
      }
    
  }

  async deleteSliderImage(id?: number): Promise<boolean> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.sliderEndpoint).concat(`/${id}`),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status } = await response
      return status === 200
    
  }

  async findOne(id: number): Promise<Lib.T.Customer.FindOneCustomerResult> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status === 200) {
        return {
          success: true,
          customer: data,
        }
      }
      return {
        success: false,
      }
    
  }
}
