import { getFirebaseToken } from './../../../../firebase'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Crypto } from 'common/helpers/crypto.helper'
import { AuthenticationService } from 'common/services'
import { SavedUser } from 'common/typing/saved-user.interface'
import { PermissionsType, RolesTRType } from 'common/routes/types/roles.type'
import { Storage } from 'common/helpers/storage'
import { useLoader } from 'common/hooks/use-loader'

type NoneUsedType = React.MouseEvent<HTMLSpanElement, MouseEvent>

export const useVerification = () => {
  // const initialTime = 60 * 1000 * 2
  const initialTime = 120000
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [reloader, setReloader] = useState<number>(0)
  const history = useHistory()
  const [phone, setPhone] = useState<string>()
  const [code, setCode] = useState<string>()
  const [OTP, setOTP] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [comingCode, setComingCode] = useState<string>()
  const { setLoader } = useLoader()

  const onStartup = () => {
    const { search } = history.location
    let params: URLSearchParams
    try {
      params = new URLSearchParams(search)
    } catch (e) {
      return goBack()
    }

    const paramCode = params.get('c')
    const paramPhone = params.get('m')
    const paramPassword = params.get('p')
    const paramEmail = params.get('e')
    const paramOTP = params.get('otp')

    if (!paramPhone || !paramPassword || !paramEmail || !paramOTP) {
      return goBack()
    }

    //const decryptedCode = Crypto.decrypt(Crypto.UrlToEncrypted(paramCode))
    const decryptedPhone = Crypto.decrypt(Crypto.UrlToEncrypted(paramPhone))
    const decryptedPassword = Crypto.decrypt(Crypto.UrlToEncrypted(paramPassword))
    const decryptedEmail = Crypto.decrypt(Crypto.UrlToEncrypted(paramEmail))
    const decryptedOTP = paramOTP

    if (!decryptedPhone || !decryptedPassword || !decryptedEmail || !decryptedOTP) {
      return goBack()
    }

    setPhone(decryptedPhone)
    setComingCode(paramCode ? paramCode : '')
    setPassword(decryptedPassword)
    setEmail(decryptedEmail)
    setOTP(decryptedOTP)
  }

  const goBack = () => history.replace('/authenticate/login')
  // const goBack = () => {alert('xxxxxxxxxxx')}

  const reload = () => {
    setReloader(reloader + 1)
    setIsCompleted(false)
  }

  const onComplete = () => {
    setIsCompleted(true)
  }

  const parseValue = (value: number): string => {
    return value < 10 ? '0' + value : value + ''
  }

  const onResend = (_: NoneUsedType) => {
    reload()
    // resend api
  }

  const verify = async () => {
    if (!email || !password || !code || !OTP) {
      return goBack()
    }
    const token = await getFirebaseToken()

    if (token) {
      const authService = new AuthenticationService(email, password, token!)
      setLoader(true)
      const result = await authService.verify(code, token, OTP)
      setLoader(false)
      const { success, data } = result

      if (!success || !data) {
        return
      }

      const { access_token, refresh_token, user } = data

      if (!access_token) {
        return
      }

      const userData: SavedUser = {
        accessToken: access_token,
        refreshToken: refresh_token,
        group: {
          id: user.group.id,
          name: user.group.name as RolesTRType,
        },
        permissions: user.role,
        userType: user?.userType,
        username: user.username,
        phone: user.phone,
        id: user.id,
        time: new Date().toISOString(),
        employee: data.user.employee,
        avatar: user?.avatar
      }

      Storage.set<SavedUser>('user', userData)

      window.location.href = '/dashboard'
    }
  }

  useEffect(onStartup, [])
  return {
    counter: {
      reloader,
      onComplete,
      initialTime,
      parseValue,
      isCompleted,
      onResend,
    },
    info: {
      comingCode,
      phone,
    },
    on: {
      verify,
    },
    code: {
      value: code,
      setValue: setCode,
    },
  }
}
