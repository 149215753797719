import * as Lib from './lib'
import { BaseService } from './BaseService'
import { ApiCaller, IApiCallerConfig } from 'common/helpers/api-caller.helper'
import { DOM } from 'common/helpers/dom'
import { Room } from './lib/types/messaging'

export class MessagingService extends BaseService{
  endpoint = 'messaging'

  constructor() {
    super();
  }

  async createGroup(body: {
    userIds: number[]
    groupData: Lib.T.Messaging.MessagingGroupType
  }): Promise<{ success: boolean; room: Room | undefined }> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/group'),
      headers: { authorization: this.accessToken },
      data: body,
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 201) {
        return { success: false, room: undefined }
      }

      return { success: true, room: data }
    
  }

  async addMemberToGroup(body: { userIds: number[]; roomId: number }): Promise<{ success: boolean; room: Room | undefined }> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/group/${body.roomId}/user`),
      headers: { authorization: this.accessToken },
      data: body,
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 201) {
        return { success: false, room: undefined }
      }

      return { success: true, room: data }
    
  }

  async getOrCreatePersonalRoom(body: { contactUserId: number }): Promise<{ success: boolean; roomId: number | undefined }> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/room'),
      headers: { authorization: this.accessToken },
      data: body,
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 201) {
        return { success: false, roomId: undefined }
      }
      return { success: true, roomId: data.roomId }
    
  }

  async getRoomMessages(
    roomId: number,
    page: number,
    limit: number,
    lastMessageId?: number,
    messageIds?: string,
  ): Promise<{ success: boolean; messages: Lib.T.Messaging.Message[] | undefined; count: number }> {

    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat(`/room/${roomId}`)
        .concat('/message')
        .concat(
          DOM.objectToSearch({
            page,
            limit,
            isBeforeLastMessageId: lastMessageId ? false : undefined,
            lastMessageId: lastMessageId ?? undefined,
            messageIds: messageIds ?? undefined,
          }),
        ),

      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false, messages: undefined, count: 0 }
      }
      return { success: true, messages: data.messages, count: data.count }
    
  }

  async sendNewMessage(body: {
    text?: string
    type: string
    file?: string
    location?: number[]
    repliedMessageId?: number
    roomId: number
  }): Promise<{ success: boolean; message: Lib.T.Messaging.Message | undefined }> {
    const config: IApiCallerConfig = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/room/${body.roomId}`).concat('/message'),
      headers: { authorization: this.accessToken },
      data: body,
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 201) {
        return { success: false, message: undefined }
      }
      return { success: true, message: data.message }
    
  }

  async updateSeenRoomMessages(roomId: number): Promise<{ success: boolean }> {
    const config: IApiCallerConfig = {
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/room/${roomId}`).concat('/message'),
      headers: { authorization: this.accessToken },
      data: { roomId },
    }

    const response = this.callRefresh(config)
      const { status } = await response
      if (status !== 200) {
        return { success: false }
      }
      return { success: true }
    
  }

  async getUserRooms(args?: { roomId: number | undefined }): Promise<{ success: boolean; rooms: Lib.T.Messaging.Room[] | undefined }> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('/room')
        .concat(args?.roomId ? DOM.objectToSearch({ roomIds: [args?.roomId] }) : ''),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response
      if (status !== 200) {
        return { success: false, rooms: undefined }
      }
      return { success: true, rooms: data.rooms }
    
  }

  async getTotalUnseen(): Promise<{ success: boolean; totalCount: number }> {
    const config: IApiCallerConfig = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/unseen'),

      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status, data } = await response

      if (status !== 200) {
        return { success: false, totalCount: 0 }
      }
      return { success: true, totalCount: data.totalCount }
    
  }

  async deleteGroup(id: number): Promise<{ success: boolean }> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/group/${id}`),
      headers: { authorization: this.accessToken },
    }

    const response = this.callRefresh(config)
      const { status } = await response

      if (status !== 200) {
        return { success: false }
      }
      return { success: true }
    
  }

  async deleteGroupUser(body: { userIds: number[]; roomId: number }, roomId: number): Promise<{ success: boolean }> {
    const config: IApiCallerConfig = {
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/group/${roomId}/user`),
      headers: { authorization: this.accessToken },
      data: body,
    }

    const response = this.callRefresh(config)
      const { status } = await response

      if (status !== 200) {
        return { success: false }
      }
      return { success: true }
    
  }
}
