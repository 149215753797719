import { Icon } from 'common/components/ui-kit/icon'
import { FC } from 'react'
import { Popover } from 'antd'
import { Carousel } from 'react-responsive-carousel'
import * as Lib from '.'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './styles.css'
import { imageAddresser } from 'common/helpers/image.helper'
import { Checkbox } from 'common/components/ui-kit/checkbox'
import { useScreenSize } from 'common/routes/hooks'
import moment from 'moment/moment'

export const NewsList: FC<Lib.T.listProps> = ({ news, className, openNews }): JSX.Element => {
  return (
    <>
      <Lib.S.NewsWrapper className={className}>
        <Lib.S.NewsTitleContainer>
          <Icon name="star" size={25} />
          <Lib.S.NewsTitle>SÖZER’DEN HABERLER</Lib.S.NewsTitle>
        </Lib.S.NewsTitleContainer>

        {news && news?.length > 0 ? (
          <Lib.S.NewsContainer>
            {news.map((item, index) => (
              <Lib.S.NewsBody key={index}>
                <Lib.S.IconWrapper>
                  <Icon name="newsIcon" size={25} />
                </Lib.S.IconWrapper>
                <Lib.S.NewsDescription onClick={() => openNews && openNews(item.title, item?.description, 'news')}>
                  {item?.description.length > 150 ? `${item?.description.substring(0, 150)} ...` : item?.description}
                </Lib.S.NewsDescription>
              </Lib.S.NewsBody>
            ))}
          </Lib.S.NewsContainer>
        ) : (
          <Lib.S.EmptyMessageContainer>
            <Lib.S.EmptyMessage>Haber yok</Lib.S.EmptyMessage>
          </Lib.S.EmptyMessageContainer>
        )}
      </Lib.S.NewsWrapper>
    </>
  )
}

export const AnnouncementsList: FC<Lib.T.listProps> = ({ announcements, className, openAnnouncement }): JSX.Element => {
  const { width } = useScreenSize()

  return (
    <>
      {announcements && announcements.length > 0 ? (
        <Lib.S.AnnouncementsList className={className}>
          <Carousel
            thumbWidth={100}
            showArrows={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            stopOnHover={true}
            showThumbs={false}
            showIndicators={true}
          >
            {announcements.map((item, index) => (
              <Lib.S.AnnouncementsWrapper key={index}>
                <Lib.S.AnnouncementsThumbnail
                  onClick={() => openAnnouncement && openAnnouncement(item.title, item.description, 'announcement', item.image)}
                >
                  <img
                    alt={item.title}
                    src={item.image ? imageAddresser(item.image?.image, true) : '/images/png/logo.png'}
                    style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                  />
                </Lib.S.AnnouncementsThumbnail>

                <Lib.S.AnnouncementsDescription
                  onClick={() => openAnnouncement && openAnnouncement(item.title, item.description, 'announcement', item.image)}
                >
                  {width > 1600
                    ? item.description.length > 200
                      ? `${item.description.substring(0, 200)}...`
                      : item.description
                    : item.description.length > 130
                    ? `${item.description.substring(0, 130)}...`
                    : item.description}
                </Lib.S.AnnouncementsDescription>
              </Lib.S.AnnouncementsWrapper>
            ))}
          </Carousel>
        </Lib.S.AnnouncementsList>
      ) : (
        <Lib.S.EmptyAnnouncementList>
          <Lib.S.AnnouncementEmptyMessage>Hiçbir duyuru yok</Lib.S.AnnouncementEmptyMessage>
        </Lib.S.EmptyAnnouncementList>
      )}
    </>
  )
}

export const FoodList: FC<Lib.T.listProps> = ({ foodList }): JSX.Element => {
  return (
    <>
      <Lib.S.FoodList>
        <Lib.S.FoodListThumbnail />

        <Lib.S.FoodListBody>
          <Lib.S.FoodListTitleContainer>
            <Icon name="restaurant" size={25} />
            <Lib.S.FoodListTitle>GÜNÜN MENÜSÜ</Lib.S.FoodListTitle>
          </Lib.S.FoodListTitleContainer>

          {foodList && foodList.length > 0 ? (
            <Lib.S.FoodNamesList>
              {foodList.map((item, index) => (
                <Lib.S.FoodItem key={index}>{item.name}</Lib.S.FoodItem>
              ))}
            </Lib.S.FoodNamesList>
          ) : (
            <Lib.S.EmptyAnnouncementList style={{ backgroundColor: 'transparent' }}>
              <Lib.S.AnnouncementEmptyMessage>Hiçbir yiyecek yok</Lib.S.AnnouncementEmptyMessage>
            </Lib.S.EmptyAnnouncementList>
          )}
        </Lib.S.FoodListBody>
      </Lib.S.FoodList>
    </>
  )
}

export const TodoList: FC<Lib.T.listProps> = ({ todoList, setTodoList, updateTodoList }): JSX.Element => {
  const handleCheckboxChange = async (index: number, checked: boolean, id: number) => {
    if (setTodoList) {
      setTodoList(prevTodoList => {
        if (!prevTodoList) return prevTodoList

        const newTodoList = [...prevTodoList]
        newTodoList[index] = {
          ...newTodoList[index],
          isChecked: checked,
        }
        return newTodoList
      })
    }

    if (updateTodoList) {
      const isUpdated = await updateTodoList(checked, id)

      if (!isUpdated && setTodoList) {
        setTodoList(prevTodoList => {
          if (!prevTodoList) return prevTodoList

          const newTodoList = [...prevTodoList]
          newTodoList[index] = {
            ...newTodoList[index],
            isChecked: !checked,
          }
          return newTodoList
        })
      }
    }
  }

  return (
    <>
      <Lib.S.TodoListContainer>
        <Lib.S.TodoListTitleContainer>
          <Icon name="book" size={25} />
          <Lib.S.TodoListTitle>NOTLAR</Lib.S.TodoListTitle>
        </Lib.S.TodoListTitleContainer>

        {todoList && todoList?.length > 0 ? (
          <Lib.S.TodoListBody>
            {todoList?.map((item, index) => (
              <Lib.S.TodoListItem>
                <Checkbox
                  label=""
                  name=""
                  onChange={e => handleCheckboxChange(index, e.target.checked, item.id)}
                  checked={item.isChecked}
                  style={{ width: 'auto', marginRight: 5 }}
                />
                <span className={`${item.isChecked ? 'checked' : ''}`}>
                  <Popover content={item.description}>
                    {item.description.length > 40 ? `${item.description.substring(0, 40)} ...` : item.description}
                  </Popover>
                </span>
              </Lib.S.TodoListItem>
            ))}
          </Lib.S.TodoListBody>
        ) : (
          <Lib.S.EmptyMessageContainer>
            <Lib.S.EmptyMessage>Yapılacaklar listesi boş</Lib.S.EmptyMessage>
          </Lib.S.EmptyMessageContainer>
        )}
      </Lib.S.TodoListContainer>
    </>
  )
}

export const Birthdays: FC<Lib.T.listProps> = ({ todayBirthdays, inWeekBirthdays, formatBirthday }): JSX.Element => {
  const groupedItems =
    inWeekBirthdays &&
    inWeekBirthdays.reduce((result, item, index) => {
      const groupIndex = Math.floor(index / 3)
      if (!result[groupIndex]) {
        result[groupIndex] = []
      }
      result[groupIndex].push(item)
      return result
    }, [] as Array<Lib.T.Birthdays[]>)

  return (
    <>
      <Lib.S.NewsWrapper style={{ gap: '0.5rem' }}>
        <Lib.S.NewsTitleContainer>
          <Icon name="cake" size={25} />
          <Lib.S.NewsTitle>MUTLU YILLAR</Lib.S.NewsTitle>
        </Lib.S.NewsTitleContainer>

        <Lib.S.BirthdaysContainer>
          {todayBirthdays && todayBirthdays?.length > 0 ? (
            <Lib.S.BirthdaysList>
              <Carousel
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                infiniteLoop={true}
                autoPlay={true}
                stopOnHover={true}
                showArrows={false}
              >
                {todayBirthdays &&
                  todayBirthdays.map(item => (
                    <Lib.S.TodayBirthdaysItem key={item.id}>
                      <Lib.S.TodayBirthdaysAvatar>
                        <img
                          src={item.avatar ? imageAddresser(item.avatar?.imageThumbnail, true) : '/images/png/avatar.png'}
                          alt={item.firstName}
                          style={{ width: '100%', height: '100%' }}
                        />
                      </Lib.S.TodayBirthdaysAvatar>
                      <Lib.S.TodayBirthdaysName>{item.firstName + ' ' + item.lastName}</Lib.S.TodayBirthdaysName>
                    </Lib.S.TodayBirthdaysItem>
                  ))}
              </Carousel>
            </Lib.S.BirthdaysList>
          ) : (
            <Lib.S.EmptyMessageContainer style={{ border: 'none' }}>
              <Icon name="cake" size={70} />
            </Lib.S.EmptyMessageContainer>
          )}

          {inWeekBirthdays && inWeekBirthdays?.length > 0 ? (
            <Lib.S.BirthdaysList>
              <Carousel
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                infiniteLoop={true}
                autoPlay={true}
                stopOnHover={true}
                showArrows={false}
                transitionTime={2000}
              >
                {groupedItems &&
                  groupedItems.map((group, index) => (
                    <Lib.S.InWeekBirthdaysContainer key={index}>
                      {group.map(item => (
                        <Lib.S.InWeekBirthdaysItem key={item.id}>
                          <Lib.S.InWeekBirthdaysAvatar>
                            <img
                              src={item.avatar ? imageAddresser(item.avatar?.imageThumbnail, true) : '/images/png/avatar.png'}
                              alt={item.firstName}
                              style={{ width: '100%', height: '100%' }}
                            />
                          </Lib.S.InWeekBirthdaysAvatar>
                          <Lib.S.InWeekBirthdaysName className="ellipsis">{item.firstName}</Lib.S.InWeekBirthdaysName>
                          <Lib.S.InWeekBirthdaysName className="ellipsis">{item.lastName}</Lib.S.InWeekBirthdaysName>
                          <Lib.S.InWeekBirthdaysDate>{moment(item.birthday).format('DD')} {moment(item.birthday).format('MMMM')}</Lib.S.InWeekBirthdaysDate>
                        </Lib.S.InWeekBirthdaysItem>
                      ))}
                    </Lib.S.InWeekBirthdaysContainer>
                  ))}
              </Carousel>
            </Lib.S.BirthdaysList>
          ) : (
            <Lib.S.BirthdayEmptyMessageContainer style={{ marginTop: '2rem', paddingTop: '2rem' }}>
              <Lib.S.EmptyMessage>Bu hafta doğum günü yok.</Lib.S.EmptyMessage>
            </Lib.S.BirthdayEmptyMessageContainer>
          )}
        </Lib.S.BirthdaysContainer>
      </Lib.S.NewsWrapper>
    </>
  )
}

export const Drinks: FC<Lib.T.listProps> = ({ setShowDrinkModal, drink, drinks }): JSX.Element => {
  return (
    <>
      <Lib.S.DrinksWrapper>
        <Lib.S.DrinksTitle>İÇECEKLER</Lib.S.DrinksTitle>
        <Lib.S.DrinkButton
          onClick={() => {
            if (setShowDrinkModal && drinks && drinks?.length > 0) {
              setShowDrinkModal(true)
            }
          }}
          isSelectDrink={Boolean(drink)}
          className={drinks && drinks.length < 1 ? 'disableButton' : ''}
        >
          {Boolean(drink) ? drink : 'İçecek seç'}
        </Lib.S.DrinkButton>
      </Lib.S.DrinksWrapper>
    </>
  )
}

export const Currencies: FC<Lib.T.listProps> = ({ currencies }): JSX.Element => {
  const { width } = useScreenSize()

  const groupCurrencies = (currencies?: Lib.T.currencies[]): Lib.T.currencies[][] => {
    if (currencies) {
      return currencies.reduce((result, item, index) => {
        const groupIndex = Math.floor(index / 3)
        if (!result[groupIndex]) {
          result[groupIndex] = []
        }
        result[groupIndex].push(item)
        return result
      }, [] as Lib.T.currencies[][])
    } else {
      return [] as Lib.T.currencies[][]
    }
  }

  const groupedCurrencies = groupCurrencies(currencies)

  return (
    <>
      <Lib.S.CurrenciesWrapper>
        <Lib.S.NewsTitleContainer>
          <Icon name="dollar" size={25} />
          <Lib.S.NewsTitle>DÖVİZ</Lib.S.NewsTitle>
        </Lib.S.NewsTitleContainer>

        {currencies && groupedCurrencies && currencies.length > 0 ? (
          <Carousel
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            infiniteLoop={true}
            autoPlay={true}
            stopOnHover={true}
            showArrows={false}
          >
            {groupedCurrencies.map((currencyGroup, index) => (
              <Lib.S.CurrencyGroup key={index} style={width > 1600 ? { gap: '2.5rem' } : undefined}>
                {currencyGroup.map(currency => (
                  <Lib.S.CurrencyContainer key={currency.name}>
                    <Lib.S.CurrencyTitle>{currency.name}</Lib.S.CurrencyTitle>
                    <Lib.S.CurrencyPriceTitle>
                      ALlŞ:
                      <Lib.S.CurrencyPrice>{currency.buyPrice}</Lib.S.CurrencyPrice>
                    </Lib.S.CurrencyPriceTitle>
                    <Lib.S.CurrencyPriceTitle>
                      SATIŞ:
                      <Lib.S.CurrencyPrice>{currency.sellPrice}</Lib.S.CurrencyPrice>
                    </Lib.S.CurrencyPriceTitle>
                  </Lib.S.CurrencyContainer>
                ))}
              </Lib.S.CurrencyGroup>
            ))}
          </Carousel>
        ) : (
          <p style={{ width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: '1rem', marginTop: '1rem', color: '#004e42' }}>
            Para birimi yok!
          </p>
        )}
      </Lib.S.CurrenciesWrapper>
    </>
  )
}

export const Weather: FC<Lib.T.listProps> = ({ weather }): JSX.Element => {
  return (
    <>
      <Lib.S.WeatherWrapper>
        <Lib.S.NewsTitleContainer>
          <Icon name="cloud" size={25} />
          <Lib.S.NewsTitle>HAVA DURUMU</Lib.S.NewsTitle>
        </Lib.S.NewsTitleContainer>

        {weather ? (
          <Lib.S.WeatherContainer>
            {weather.map(item => (
              <Lib.S.Weather>
                <Lib.S.WeatherIconContainer>
                  <Lib.S.WeatherIcon src={`https://www.weatherbit.io/static/img/icons/${item.icon}.png`} alt="weather" />
                </Lib.S.WeatherIconContainer>

                <Lib.S.DayName>{item.day}</Lib.S.DayName>
                <Lib.S.Temperature>{item.temperature}&deg;c</Lib.S.Temperature>
              </Lib.S.Weather>
            ))}
          </Lib.S.WeatherContainer>
        ) : (
          <Lib.S.EmptyMessageContainer style={{ marginTop: '0.5rem' }}>
            <Lib.S.EmptyMessage>Hava durumunu görüntülemede bir sorun var</Lib.S.EmptyMessage>
          </Lib.S.EmptyMessageContainer>
        )}
      </Lib.S.WeatherWrapper>
    </>
  )
}
