export type Tabs = 'weekly' | 'monthly' | 'yearly'

export interface DataType {
  label: string
  value: number
}

export type Property = 'weekly' | 'monthly' | 'yearly'

export enum HeaderTabEnum {
  YEAR = 'Yıl',
  MONTH = 'Ay',
  WEEK = 'Hafta',
}
export type HeaderTabType = HeaderTabEnum

export interface YearType {
  date: string
  period: string
  result: [{
    count: number
    monthName: string
  }]
}

export interface MonthType {
  date: string
  period: string
  result: [{
    count: number
    day: number
    checkedCount: number
    unCheckedCount: number
  }]
}

export interface WeekType {
  endDate: string
  period: string
  startDate: string
  result: [{
    dayName: {
      date: string
      dayName:string
    }
    todos: [{
      date: string
      description: string
      id: number
      isChecked: boolean
      order:number
      title:string
      assignedList: [
        {
          id: number
          name: string
          username: string
          isChecked?: boolean
        }
      ]
      assignedBy: {
        id: number
        name: string
        username: string
      }
      userChecked?:{
        username: string,
        isChecked: boolean,
        id: number
      }
    }],
    date?: string
    description?: string
    id?: number
    isChecked?: boolean
    order?:number
    title?:string
    userAssign?:{
      id: number
      username: string
    }
    userChecked?:{
      username: string,
      isChecked: boolean,
      id: number
    }
  }]
}

